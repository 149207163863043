import React, { useEffect, useMemo, useRef, useState } from 'react'
import Parse from 'parse'
import AdyenCheckout from '@adyen/adyen-web'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { logEvent } from 'firebase/analytics'
import { marketplace, money } from '@sellpy/commons'
import { Button } from '@sellpy/design-system-react-web'
import { useDispatch } from 'react-redux'
import { captureException } from '@sentry/react'
import AdyenPolicy from '../../checkout/components/AdyenPolicy.jsx'
import { adyenPaymentOptions, region } from '../../../common/region/region.js'
import { getAppAnalytics } from '../../../../../app-payments/analytics/analytics.js'
import { useAnalyticsFormattingUtils } from '../../../../../app-payments/analytics/formatAnalyticsData.js'
import {
  fetchUserCredits,
  reportAnalyticsCheckoutV2Purchase
} from '../../../common/cart/actions.js'
import CreditPayoutModal from '../../payOuts/credit/CreditPayoutModal.jsx'
import DataConsent from '../dataConsent/DataConsent.jsx'
import { getTotalBalanceForUser } from '../../../common/balance/actions.js'
import AdyenOptions, { getDetailedType } from './components/AdyenOptions.jsx'
import { getAdyenConfig } from './AdyenCheckoutConfig.jsx'
import { nothingToPayWithAdyen } from './components/helpers.js'
import ErrorHandler from './components/ErrorHandler.jsx'
import { useIsAdyenReturn } from './hooks/useIsAdyenReturn.js'
import { useMaxCreditAmount } from './hooks/useMaxCreditAmount.js'
import CreditsV2 from './components/CreditsV2.jsx'
import { useLatestPaymentMethod } from './hooks/useLatestPaymentMethod.js'
import { excludeApplePay } from './excludeApplePay.js'

export const ERRORS = {
  PARSE_ERROR: 'parseError',
  ADYEN_ERROR: 'adyenOptions'
}

const BottomWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade8};
  padding: 16px;
`

const TopWrapper = styled.div`
  padding: 16px;
`

/**
 * @param {function} paymentStart - Expect a cloud function that uses the createSessionCheckoutAdyenWrapper, this will fetch a session object that AdyenSessionPayment uses to initate the payment sessions.
 * @param {function} paymentSubmit - Expect a cloud function that uses the runSessionCheckoutAdyenWrapper,
 * this will run when a customer click on the pay button but an error will break the payment flow. The function will get adyenPaymentData, creditAmount and dataUsageConsent from AdyenSessionPayment
 * @param {object} totalAmount - The total amount (in subUnit) that the customer should pay.
 * @param {function} amountElement - A react element/component that visulize the the amount the customer is will pay. The element will get adyenAmount and creditsAmount from AdyenSessionPayment depending on what the customer has selected.
 * @param {boolean} [enableCredits=true] - Define if credits should be shown for the customer, this doesn't stop the customer from using credits, a validation in the backend needs to be set as well.
 * @param {array} [userPermissions] - An array of the user permissions that the user can or need to consent to.
 * @param {array} [appPaymentReservationIds] - An array of the reservation ids that the customer is paying for. Used for tracking app events, and is only included if it's an item payment from the app.
 */

const AdyenSessionPayment = ({
  paymentStart,
  paymentSubmit,
  totalAmount,
  amountElement,
  enableCredits = true,
  userPermissions,
  isLoyaltyPayment = false,
  appPaymentReservationIds
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('adyenSessionPayment')
  const [creditPayoutModalOpen, setCreditPayoutModalOpen] = useState(false)
  const dispatch = useDispatch()
  const [checkout, setCheckout] = useState()
  const [paymentId, setPaymentId] = useState()
  const [loading, setLoading] = useState(true)
  const cardRef = useRef()
  const savedCardRef = useRef()
  const klarnaRef = useRef()
  const klarnaPaynowRef = useRef()
  const klarnaAccountRef = useRef()
  const swishRef = useRef()
  const paypalRef = useRef()
  const idealRef = useRef()
  const mobilepayRef = useRef()
  const bancontactRef = useRef()
  const onlineBankingPLRef = useRef()
  const refs = useMemo(
    () => ({
      card: cardRef,
      saved_card: savedCardRef,
      klarna: klarnaRef,
      klarna_paynow: klarnaPaynowRef,
      klarna_account: klarnaAccountRef,
      swish: swishRef,
      paypal: paypalRef,
      ideal: idealRef,
      mobilepay: mobilepayRef,
      bcmc: bancontactRef,
      onlineBanking_PL: onlineBankingPLRef
    }),
    [cardRef, savedCardRef, klarnaRef, klarnaPaynowRef, swishRef, paypalRef, idealRef]
  )

  const { latestPaymentMethod, loading: latestPaymentMethodLoading } = useLatestPaymentMethod()

  const selectedPaymentMethodIndexRef = useRef(0)

  const { search, pathname } = useLocation()
  const isReturnFromAdyen = useIsAdyenReturn()
  const {
    handleSubmit,
    watch,
    register,
    trigger,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty }
  } = useForm({})

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (nothingToPayWithAdyen(checkout)) {
        reportAnalyticsCheckoutV2Purchase(paymentId)
        await paymentSubmit({
          paymentId,
          creditAmount,
          dataUsageConsent: data.dataUsageConsent
        }).then((payment) => {
          navigate(`/payment/${payment.id}`)
        })
      } else {
        const paymentComponent = checkout.components.find(({ _id }) => _id === data.adyenOptions)

        if (!paymentComponent) throw new Error('No payment component found')
        paymentComponent.submit()

        if (paymentComponent.state && !paymentComponent.state.isValid) {
          setLoading(false)
        }
      }
    } catch (error) {
      captureException(error)
      if (error instanceof Parse.Error) {
        setError(ERRORS.PARSE_ERROR, error)
      } else {
        setError(ERRORS.ADYEN_ERROR, { message: error.message })
      }
      setLoading(false)
    }
  }

  const userId = Parse.User.current()?.id
  const maxCreditAmount = useMaxCreditAmount(totalAmount)
  const creditAmount = watch('useCredits') ? money.toBaseUnit(maxCreditAmount).amount : 0

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const urlSessionId = urlParams.get('sessionId')
    const redirectResult = urlParams.get('redirectResult')
    const getSession = async () => {
      setLoading(true)
      let session
      try {
        session = urlSessionId
          ? { id: urlSessionId }
          : await paymentStart({
              creditAmount: parseFloat(creditAmount),
              returnUrl: `${window.location.origin}${pathname}`
            })
      } catch (e) {
        captureException(e)
        setError(ERRORS.PARSE_ERROR, e)
        setLoading(false)
        setCheckout(null)
        return
      }
      setPaymentId(session.reference)
      /**
       * If adyen amount is zero the result of the createSessionWrapper is an object
       * that only includes reference.
       */
      if (!session.id) {
        setCheckout(null)
        setLoading(false)
        return
      }
      try {
        const getValidFormData = async () => {
          const formDataIsValid = await trigger()
          if (!formDataIsValid) return new Error('invalidFormData')
          return getValues()
        }
        const checkout = await AdyenCheckout(
          getAdyenConfig({
            session,
            navigate,
            paymentSubmit,
            getValidFormData,
            setError,
            setLoading,
            userExists: Boolean(userId),
            urlParams,
            clearErrors,
            creditAmount
          })
        )
        if (checkout?.paymentMethodsResponse?.paymentMethods) {
          checkout.paymentMethodsResponse.paymentMethods.forEach((props) =>
            checkout.create(props.type, {
              ...props
            })
          )
          // isLoyaltyPayment here disables stored card, potentially temporary fix
          // until we can make certain adyenRecurringDetailReference can be collected on
          // stored cards.

          if (
            checkout?.paymentMethodsResponse?.storedPaymentMethods?.length > 0 &&
            region() !== 'NL' &&
            !isLoyaltyPayment
          ) {
            const props = checkout?.paymentMethodsResponse?.storedPaymentMethods[0]
            checkout.create(props.type, {
              ...props
            })
          }
          if (redirectResult) {
            checkout.submitDetails({ details: { redirectResult: redirectResult } })
          }
          const sortedComponents = excludeApplePay(checkout?.components).sort((a, b) => {
            const aType = getDetailedType(a)
            const bType = getDetailedType(b)
            return adyenPaymentOptions.indexOf(aType) - adyenPaymentOptions.indexOf(bType)
          })
          // It's not optimal to mutate the checkout object, but since it's being widely used
          // this is an easy way of getting the components sorted everywhere
          checkout.components = sortedComponents
          setCheckout(checkout)
        }
        setLoading(false)
      } catch (error) {
        captureException(error)
        setError(ERRORS.ADYEN_ERROR, { message: error.message })
        setLoading(false)
      }
    }
    getSession()
  }, [
    userId,
    pathname,
    search,
    creditAmount,
    getValues,
    setError,
    trigger,
    navigate,
    paymentStart,
    paymentSubmit,
    isLoyaltyPayment,
    clearErrors
  ])

  useEffect(() => {
    const index = selectedPaymentMethodIndexRef.current
    const selectedOptionId = checkout?.components[index]?._id
    if (!selectedOptionId) {
      return
    }
    setValue('adyenOptions', selectedOptionId)
  }, [checkout, setValue])

  const setSelectedPaymentMethodIndex = (index) => {
    selectedPaymentMethodIndexRef.current = index
  }

  const { getAnalyticsFormattedItem } = useAnalyticsFormattingUtils()
  const selectedAdyenOption = watch('adyenOptions')
  useEffect(() => {
    if (!selectedAdyenOption || !checkout || !appPaymentReservationIds) return

    const selectedComponent = checkout.components.find(({ _id }) => _id === selectedAdyenOption)
    if (!selectedComponent) return

    const selectedType = getDetailedType(selectedComponent)

    const fetchDataAndLogAnalyticsEvent = async () => {
      const reservations = await new Parse.Query('ItemReservation')
        .containedIn('objectId', appPaymentReservationIds)
        .find()
      const itemIds = reservations.map((reservation) => reservation.get('item').id)

      logEvent(getAppAnalytics(), 'add_payment_info', {
        payment_type: selectedType,
        items: await Promise.all(
          itemIds.map((itemId) => getAnalyticsFormattedItem({ item: { objectId: itemId } }))
        )
      })
    }
    fetchDataAndLogAnalyticsEvent()
  }, [selectedAdyenOption, checkout, appPaymentReservationIds, getAnalyticsFormattedItem])

  /**
   * If `window.paymentData` exists then we know this payment is initiated from Sellphone,
   * and we should open the credit payout in the app instead of the link.
   */
  const windowPaymentDataExists = Boolean(window.paymentData)

  useEffect(() => {
    if (windowPaymentDataExists) {
      window.fetchUserCredits = () => {
        dispatch(fetchUserCredits(region()))
        dispatch(getTotalBalanceForUser())
      }

      return () => {
        delete window.fetchUserCredits
      }
    }
  }, [windowPaymentDataExists, dispatch])

  useEffect(() => {
    if (!creditPayoutModalOpen) dispatch(fetchUserCredits(region()))
  }, [creditPayoutModalOpen, dispatch])

  const handleOnClick = () =>
    windowPaymentDataExists
      ? window.ReactNativeWebView.postMessage('openCreditPayout')
      : setCreditPayoutModalOpen(true)

  return (
    <div>
      <TopWrapper>
        {enableCredits && (
          <>
            {!windowPaymentDataExists && (
              <CreditPayoutModal
                isOpen={creditPayoutModalOpen}
                setIsOpen={setCreditPayoutModalOpen}
              />
            )}
            <CreditsV2
              totalAmount={totalAmount}
              handleOnClick={handleOnClick}
              register={register}
              loading={loading}
              onChange={() => {
                // Resets the Adyen component
                setValue('adyenOptions', undefined)
                // "Force-re-initialize" the paypal component to make it use the latest session
                const paypalComponent = checkout?.components.find(
                  (component) => component.type === 'paypal'
                )
                // eslint-disable-next-line no-unused-expressions
                paypalComponent?.unmount()
              }}
            />
          </>
        )}
        {!nothingToPayWithAdyen(checkout) && !latestPaymentMethodLoading && (
          <AdyenOptions
            key={paymentId}
            errors={errors}
            register={register}
            refs={refs}
            setValue={setValue}
            setSelectedPaymentMethodIndex={setSelectedPaymentMethodIndex}
            checkout={checkout}
            latestPaymentMethod={latestPaymentMethod}
            disabled={loading || isReturnFromAdyen}
            watch={watch}
            isDirty={isDirty}
            validate={Boolean}
          />
        )}
      </TopWrapper>
      <BottomWrapper>
        <amountElement.type
          {...amountElement.props}
          adyenAmount={{
            currency: checkout?.options?.amount?.currency,
            amount: checkout?.options?.amount?.value
          }}
          creditAmount={money.toSubUnit({
            currency: marketplace.CURRENCY[region()],
            amount: creditAmount
          })}
        />
        <DataConsent
          permissions={userPermissions}
          register={register}
          errors={errors}
          showCheckoutPolicies
        />
        <ErrorHandler errors={errors} clearErrors={clearErrors} />
        <div
          ref={refs.paypal}
          style={{ display: !watch('adyenOptions')?.includes('paypal') ? 'none' : 'inherit' }}
        />
        <Button
          label={t('payButton')}
          loading={loading}
          fullWidth
          style={{
            margin: '0 0 8px 0',
            display: watch('adyenOptions')?.includes('paypal') ? 'none' : 'inherit'
          }}
          onClick={handleSubmit(onSubmit)}
          disabled={Object.keys(errors).length > 0}
        />
        <AdyenPolicy />
      </BottomWrapper>
    </div>
  )
}

export default AdyenSessionPayment

import React, { useEffect, useMemo, useState } from 'react'
import { ICONS, Icon, Span } from '@sellpy/design-system-react-web'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { searchUrls } from '@sellpy/commons'
import { Link } from 'react-router-dom'
import { isFeatureEnabled } from '../../../featureToggle'
import {
  analyticsBagOrderButton,
  analyticsMenuNavigation,
  analyticsOrderPickupButton
} from '../../../../common/analytics/actions'
import { algoliaCategorySearchWithSubCategories } from '../../../../common/lib/algolia'
import { initialBuyOrSellList } from '../../menuItems'
import { region } from '../../../../common/region/region'
import {
  ListItem,
  StyledSpan,
  ArrowWrapper,
  StyledCategoryButton,
  StyledRouterLink,
  StyledButton,
  ListIcon,
  CategoryIcon
} from './styles'

const ListButton = ({ label, onClick, icon, link, blob }) => (
  <ListItem key={label}>
    <StyledButton
      onClick={onClick}
      role='menuitem'
      as={link ? Link : 'button'}
      to={link ?? undefined}
    >
      {icon && <ListIcon name={icon} />}
      <StyledSpan noMargin design='body3'>
        {label}
        {blob}
      </StyledSpan>
      {!link && (
        <ArrowWrapper>
          <Icon name={ICONS.CHEVRON_RIGHT} style={{ color: 'black' }} />
        </ArrowWrapper>
      )}
    </StyledButton>
  </ListItem>
)

const getBaseList = (section, category) => {
  if (section === 'sell') {
    return initialBuyOrSellList.sell
  }

  if (section === 'buy' && !category) {
    return initialBuyOrSellList.buy
  }

  if (section === 'buy') {
    return (
      category.subCategories?.map(([itemValue, itemCount]) => ({
        label: itemValue.split(' > ').pop(),
        value: itemValue,
        count: itemCount,
        icon: category.icon,
        parentCategory: category
      })) ?? []
    )
  }

  return []
}

const categoryCache = new Map()

const useAlgoliaDynamicCategory = (category, { deactivateFetch = false } = {}) => {
  const [currentLevelCategories, setCurrentLevelCategories] = useState(null)

  useEffect(() => {
    const pathSegments = category?.value.split(' > ') ?? []
    const level = pathSegments.length

    const cacheKey = category ? category.value : ''

    const prevCache = categoryCache.get(cacheKey)

    if (prevCache) {
      setCurrentLevelCategories(prevCache)
    } else {
      setCurrentLevelCategories(null)

      if (!deactivateFetch) {
        algoliaCategorySearchWithSubCategories(category, level).then((categories) => {
          categoryCache.set(cacheKey, categories)
          setCurrentLevelCategories(categories)
        })
      }
    }
  }, [category, deactivateFetch])

  return currentLevelCategories
}

const BuyOrSellList = ({ section, closeMenu, scrollToTop, isOpen }) => {
  const { t } = useTranslation('menu')
  const theme = useTheme()
  const [nestedCategory, setNestedCategory] = useState(null)

  const baseList = getBaseList(section, nestedCategory)
  const dynamicAlgoliaCategories = useAlgoliaDynamicCategory(nestedCategory, {
    deactivateFetch: !isOpen
  })

  const mergedList = useMemo(
    () =>
      baseList.map((baseItem) => {
        const algoliaDynamic = dynamicAlgoliaCategories?.find(
          (algoliaCategory) => algoliaCategory.label === baseItem.label
        )
        return {
          ...baseItem,
          isAlgoliaDynamic: Boolean(algoliaDynamic),
          link:
            baseItem.link ??
            (baseItem.value && !algoliaDynamic?.subCategories?.length
              ? searchUrls.stringifySearchStateV2(region())({
                  hierarchicalMenu: { 'categories.lvl0': baseItem.value.split(' > ') }
                })
              : undefined),
          subCategories: algoliaDynamic?.subCategories
        }
      }),
    [baseList, dynamicAlgoliaCategories]
  )

  useEffect(() => {
    if ((section === 'sell' && nestedCategory) || (!isOpen && nestedCategory)) {
      setNestedCategory(null)
    }
  }, [section, nestedCategory, isOpen])

  return (
    <>
      {nestedCategory && (
        <>
          <StyledCategoryButton onClick={() => setNestedCategory((prev) => prev.parentCategory)}>
            <Icon
              name={ICONS.ARROW_LEFT}
              style={{
                color: theme.color.grey.shade2,
                margin: '0',
                position: 'absolute',
                left: '8px'
              }}
            />
            <Span noMargin design='body3' style={{ display: 'inline-flex', alignItems: 'center' }}>
              {nestedCategory.icon && <CategoryIcon name={nestedCategory.icon} />}
              {nestedCategory.label}
            </Span>
          </StyledCategoryButton>

          <StyledRouterLink
            color='grey'
            noMargin
            key={`${nestedCategory.label}-all`}
            onClick={() => {
              closeMenu(`${nestedCategory.label}-all`)
              analyticsMenuNavigation({
                label: `closeSubMenu${nestedCategory.label}`,
                action: 'closeSubMenu'
              })
            }}
            to={searchUrls.stringifySearchStateV2(region())({
              hierarchicalMenu: { 'categories.lvl0': nestedCategory.value.split(' > ') }
            })}
          >
            {t('buyOrSellList.everyThingInCategory', { category: nestedCategory.label })}
          </StyledRouterLink>
        </>
      )}
      {mergedList.map((category) => {
        const { label, link, featureToggle, icon, blob, value, isAlgoliaDynamic } = category
        return (
          isFeatureEnabled(featureToggle) && (
            <ListButton
              key={label}
              blob={blob}
              label={value ? label : t(`buyOrSellList.${label}`)}
              icon={!nestedCategory && icon}
              link={link}
              onClick={() => {
                if (link) {
                  scrollToTop()
                  closeMenu(label)
                } else {
                  setNestedCategory(category)
                }

                if (link === '/order-bag') analyticsBagOrderButton({ location: 'Hamburger menu' })
                if (link === '/orderPickUp')
                  analyticsOrderPickupButton({ location: 'Hamburger menu' })

                if (isAlgoliaDynamic) {
                  if (link) {
                    analyticsMenuNavigation({
                      label: `closeSubMenu${category.label}`,
                      action: 'closeSubMenu'
                    })
                  } else {
                    analyticsMenuNavigation({
                      label: `openSubMenu${category.label}`,
                      action: 'openSubMenu'
                    })
                  }
                }
              }}
            />
          )
        )
      })}
    </>
  )
}

export default BuyOrSellList

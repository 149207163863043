import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Breadcrumbs,
  Button,
  H4,
  Icon,
  ICONS,
  P,
  RouterLink
} from '@sellpy/design-system-react-web'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { SIZES } from '../uiComponents'
import LayoutCenteredColumn, {
  CENTERED_COLUMN_VARIANTS
} from '../components/LayoutCenteredColumn.jsx'
import { mediaQueries } from '../uiComponents/mediaQueries'
import { TOAST_TYPE } from '../uiComponents/notifications/Toasts.jsx'
import { showToast } from '../../common/ui/actions.js'
import { useResponsive } from '../hooks/index.js'
import { region } from '../../common/region/region.js'

const ViewBackground = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade10};
  height: 450px;
`

const ViewContainer = styled.div`
  padding: 8px 0;
  max-width: 664px;
  background-color: ${({ theme }) => theme.color.white.default};
  margin: 0 auto;
  border-radius: 8px;

  ${mediaQueries.phone} {
    width: 100%;
    padding: 0 0 40px;
  }
`

const BreadCrumbContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 6px;

  ${mediaQueries.phone} {
    padding-left: 8px;
    background-color: ${({ theme }) => theme.color.white.default};
  }
`

const ContentContainer = styled.div`
  padding: 16px 16px 0;
  gap: 24px;

  ${mediaQueries.phone} {
    padding: 0 16px;
    background-color: ${({ theme }) => theme.color.white.default};
  }
`

const ChoicesSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid;
  border-top-color: ${({ theme }) => theme.color.grey.shade8};
`

const setAutomaticPayoutMethodMutation = gql`
  mutation setAutomaticPayoutMethod($method: String!, $region: String!) {
    setAutomaticPayoutMethod(method: $method, region: $region) {
      credits
    }
  }
`

const CHOICES = [
  {
    icon: ICONS.SELLPY_CIRCLE,
    name: 'credits',
    url: '#',
    action: async (setAutomaticPayoutMethod, navigate, dispatch, t) => {
      try {
        const response = await setAutomaticPayoutMethod({
          variables: {
            method: 'credits',
            region: region()
          }
        })
        const automaticPayout = response?.data?.setAutomaticPayoutMethod
        if (automaticPayout?.credits) {
          navigate('/payout-setup/confirmation?method=credits')
        } else {
          dispatch(
            showToast({
              header: t('common:errorToast.header'),
              body: t('common:errorToast.body'),
              type: TOAST_TYPE.ERROR
            })
          )
        }
      } catch (e) {
        dispatch(
          showToast({
            header: t('common:errorToast.header'),
            body: t('common:errorToast.body'),
            type: TOAST_TYPE.ERROR
          })
        )
      }
    }
  },
  {
    icon: ICONS.WALLET,
    name: 'bankAccount',
    url: 'bankAccount'
  },
  {
    icon: ICONS.TEAMWORK,
    name: 'charity',
    url: 'charity'
  }
]

const Setup = () => {
  const theme = useTheme()

  const dispatch = useDispatch()
  const { t } = useTranslation(['payoutSetup', 'common'])
  const navigate = useNavigate()
  const { isMobile } = useResponsive()

  const [setAutomaticPayoutMethod] = useMutation(setAutomaticPayoutMethodMutation, {
    onError: () => {
      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }
  })

  return (
    <>
      <ViewBackground>
        <LayoutCenteredColumn variant={CENTERED_COLUMN_VARIANTS.NARROW}>
          <BreadCrumbContainer>
            <Breadcrumbs
              options={[
                {
                  link: '/withdraw',
                  label: (
                    <P design='body2' noMargin={true}>
                      <Icon
                        name={'ARROW_LEFT'}
                        style={{
                          marginRight: '8px'
                        }}
                      />
                      {t('payoutSetup:mainPage.breadcrumbs.previous')}
                    </P>
                  )
                }
              ]}
            />
          </BreadCrumbContainer>
          <ViewContainer>
            <ContentContainer>
              <H4>{t('mainPage.description.header')}</H4>
              <P design='body3' noMargin style={{ color: theme.color.grey.shade3 }}>
                {t('mainPage.description.body')}
              </P>
              <ChoicesSection>
                {CHOICES.map((choice, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      justifyContent: 'space-between',
                      padding: isMobile ? '16px 0' : '16px',
                      borderBottom: '1px solid',
                      borderBottomColor: theme.color.grey.shade8
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'space-between',
                        gap: 16
                      }}
                    >
                      <Icon name={choice.icon} style={{ fontSize: '2rem', alignSelf: 'center' }} />
                      <div>
                        <P design='body2' noMargin>
                          {t(`choice.${choice.name}.header`)}
                        </P>
                        <P
                          design='body3'
                          noMargin
                          style={{
                            color: theme.color.grey.shade3,
                            maxWidth: isMobile ? '250px' : '450px',
                            textWrap: 'wrap'
                          }}
                        >
                          {t(`choice.${choice.name}.body`)}
                        </P>
                      </div>
                    </div>
                    <RouterLink to={choice.url} noMargin style={{ alignSelf: 'center' }}>
                      <Button
                        label={t('choice.selectButton.title', 'Select')}
                        size={SIZES.SMALL}
                        color={'blue'}
                        onClick={() => {
                          choice.action &&
                            choice.action(setAutomaticPayoutMethod, navigate, dispatch, t)
                        }}
                      />
                    </RouterLink>
                  </div>
                ))}
              </ChoicesSection>
            </ContentContainer>
          </ViewContainer>
        </LayoutCenteredColumn>
      </ViewBackground>
    </>
  )
}

export default Setup

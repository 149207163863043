import { P, H3, MagnifierEyeColorful } from '@sellpy/design-system-react-web'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import { dynamicDescription, money, readableUrls, loyalty } from '@sellpy/commons'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ItemTitle, ItemSubtitle, ItemLink } from '../../../components/ItemOutputComponents.jsx'
import HoverImage from '../../../components/HoverImage.jsx'
import { analyticsItemClick } from '../../../../common/analytics/actions'
import { selectImagesByItemStatus, selectImagesForUse } from '../../../item/tools'
import { locale, localizedMetadata } from '../../../../common/region/locale'
import { PDP_TYPE } from '../../../item/constants'
import { currentUserSelector } from '../../../../common/user/selectors'
import { useImageAlt } from '../../../item/components/hooks/useImageAlt'

const Container = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
`
const StyledItemTitle = styled(ItemTitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TopRightContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`

const TopLeftContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
`

const PriceContainer = styled.div`
  display: flex;
`

const InfoBlobsContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 1;
`

const TextContainer = styled.div`
  margin: 0.5rem 0;
`

const ItemTile = ({
  item,
  priceOrValue,
  onClick,
  topRight,
  topLeft,
  infoBlobs,
  bottom,
  className,
  rejectReason,
  pdpType = PDP_TYPE.BUYER,
  analyticsProps
}) => {
  if (!(item instanceof Map)) item = fromJS(item)
  if (!(priceOrValue instanceof Map)) priceOrValue = fromJS(priceOrValue)

  analyticsProps = analyticsProps || { objectID: item.get('objectID') || item.get('objectId') }

  const images = selectImagesByItemStatus(item)

  const handleClick = (pdpType) => {
    if (onClick) onClick()
    analyticsItemClick({
      id: item.get('objectID') || item.get('objectId'),
      brand: item.get('metadata')?.get('brand'),
      type: item.get('metadata')?.get('type'),
      price: priceOrValue && money.toBaseUnit(priceOrValue).get('amount'),
      currency: priceOrValue?.get('currency'),
      pdpType: pdpType
    })
  }

  return (
    <Container
      className={className}
      itemScope
      itemType='http://schema.org/Product'
      {...analyticsProps}
    >
      <meta itemProp='brand' content={item.get('metadata')?.get('brand')} />
      <TopRightContainer key={`${item.get('objectID')}-topRightInfoBubble`}>
        {topRight}
      </TopRightContainer>
      <TopLeftContainer key={`${item.get('objectID')}-topLeftInfoBubble`}>
        {topLeft}
      </TopLeftContainer>
      {pdpType === PDP_TYPE.BUYER ? (
        <ItemLink
          key={`${item.get('objectID')}-link`}
          item={item}
          onClick={() => handleClick(pdpType)}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column'
          }}
          itemProp='url'
        >
          <ItemInfo
            infoBlobs={infoBlobs}
            images={images}
            item={item}
            rejectReason={rejectReason}
            priceOrValue={priceOrValue}
          />
        </ItemLink>
      ) : (
        <Link
          to={item.get('p2p') ? readableUrls.myCircleItemLink(item) : readableUrls.myItemLink(item)}
          className={className}
          onClick={() => handleClick(pdpType)}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <ItemInfo
            infoBlobs={infoBlobs}
            images={images}
            item={item}
            rejectReason={rejectReason}
            priceOrValue={priceOrValue}
          />
        </Link>
      )}
      {bottom && <div>{bottom}</div>}
    </Container>
  )
}

const ItemInfo = ({ infoBlobs, images, item, rejectReason, priceOrValue }) => {
  const userId = useSelector(currentUserSelector)
  const theme = useTheme()
  return (
    <>
      <div style={{ position: 'relative' }}>
        {loyalty.isFirstAccessAlgoliaItem(process.env.REGION)({
          algoliaItem: item.toJS(),
          sellerUserId: userId
        }) && (
          <MagnifierEyeColorful
            style={{
              height: '24px',
              position: 'absolute',
              top: '8px',
              left: '8px',
              zIndex: 1,
              color: theme.color.grey.shade2
            }}
          />
        )}
        <InfoBlobsContainer>{infoBlobs}</InfoBlobsContainer>
        <ItemImage images={images} item={item} />
      </div>
      <TextContainer>
        <StyledItemTitle
          design='body4'
          component={H3}
          item={item}
          defaultValue='&nbsp;'
          noMargin
          itemProp='name'
        />
        <Subtitle item={item} rejectedItem={Boolean(rejectReason)} />
      </TextContainer>
      {rejectReason ? (
        <P italic center>
          {rejectReason}
        </P>
      ) : (
        <Price priceOrValue={priceOrValue} />
      )}
    </>
  )
}

const ItemImage = ({ images, item }) => {
  const getAlt = useImageAlt({})
  const selectedImages = selectImagesForUse(images)
  return (
    <HoverImage
      itemProp='image'
      primaryImage={selectedImages.get(0)}
      secondaryImage={selectedImages.get(1)}
      altPrimary={getAlt({ item: item.toJS(), index: 0 })}
      altSecondary={getAlt({ item: item.toJS(), index: 1 })}
      darken
    />
  )
}

const Price = ({ priceOrValue }) => (
  <PriceContainer itemProp='offers' itemScope itemType='http://schema.org/Offer'>
    <meta itemProp='priceCurrency' content={priceOrValue?.get('currency')} />
    <P
      itemProp='price'
      content={priceOrValue && money.toBaseUnit(priceOrValue).get('amount')}
      noMargin
      design={'body4'}
    >
      {(priceOrValue && money.formatSubUnit(priceOrValue)) || <>&nbsp;</>}
    </P>
  </PriceContainer>
)

const rejectedItemTitleOrder = ['brand', 'type', 'size', 'model', 'color', 'material']
const Subtitle = ({ rejectedItem, item }) => {
  const theme = useTheme()
  if (rejectedItem) {
    return (
      <P design={'body3'} style={{ color: theme.color.grey.shade3 }} noMargin>
        {dynamicDescription.itemSubtitle({
          metadata: item.get(localizedMetadata) || item.get('metadata'),
          titleOrder: rejectedItemTitleOrder,
          locale
        })}
      </P>
    )
  } else {
    return (
      <StyledItemTitle
        as={ItemSubtitle}
        design={'body3'}
        component={P}
        item={item}
        defaultValue='&nbsp;'
        style={{ color: theme.color.grey.shade3 }}
        noMargin
      />
    )
  }
}

ItemTile.propTypes = {
  item: PropTypes.object.isRequired,
  priceOrValue: PropTypes.object,
  onClick: PropTypes.func,
  infoBlobs: PropTypes.any,
  bottom: PropTypes.any,
  rejectReason: PropTypes.string
}

export default ItemTile

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { H3 } from '@sellpy/design-system-react-web'
import { paymentServiceProviders } from '../../common/region/config'
import { DeliveryPaymentWrapper } from '../app/Footer/components/deliveryPaymentInfo/styles'
import { PAYMENT_SERVICE_PROVIDER_ICONS } from './constants'

export const IconContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`

const PaymentOptionIcons = ({ showHeadline = false, showTrustScore }) => {
  const { t } = useTranslation('footer')
  return (
    <DeliveryPaymentWrapper showTrustScore={showTrustScore}>
      {showHeadline && <H3 design='body6'>{t('paymentOptions').toUpperCase()}</H3>}
      <IconContainer>
        {paymentServiceProviders.map((paymentOption) => (
          <React.Fragment key={paymentOption}>
            {PAYMENT_SERVICE_PROVIDER_ICONS[paymentOption]}
          </React.Fragment>
        ))}
      </IconContainer>
    </DeliveryPaymentWrapper>
  )
}

export default PaymentOptionIcons

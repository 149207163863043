import styled from 'styled-components'
import { Icon, P, Span } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../uiComponents/mediaQueries'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
export const Container = styled.div`
  width: 400px;
  ${mediaQueries.phone} {
    width: 90%;
  }
`

export const BlueBanner = styled.div`
  height: 152px;
  background-color: ${({ theme }) => theme.color.blue.default};
  display: flex;
  justify-content: center;
`
export const WhiteBox = styled.div`
  width: calc(400px - 32px);
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white.default};
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.16);
  padding: 16px;
  margin: -161px auto 0 auto;
  ${mediaQueries.tablet} {
    margin: -114px auto 0 auto;
  }
  ${mediaQueries.phone} {
    width: 80%;
  }
`

export const WhiteBoxAB = styled(WhiteBox)`
  width: 400px;
  ${mediaQueries.phone} {
    width: 90%;
  }
  padding: 16px 0;
`

export const BalanceInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const BalanceInfoContainerAB = styled(BalanceInfoContainer)`
  gap: 16px;
`

export const WhiteBoxContent = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px 0;
`

export const WhiteBoxContentAB = styled(WhiteBoxContent)`
  padding: 8px 0;
`

export const EventButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  /* The above is for removing default button styles. */
  width: 100%;
`

export const EventTitle = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
  margin-bottom: 12px;
`
export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PriceParagraph = styled(P)`
  text-align: right;
`

export const EventContentBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  min-width: 0;
`

export const InfoContainer = styled.div`
  flex: 1;
  min-width: 0;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const RightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
`
export const CoverImage = styled.img`
  object-fit: cover;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.color.grey.shade8};
  border-radius: 50%;
`
export const StyledIcon = styled(Icon)`
  font-size: 2.5rem;
  background-color: ${({ theme }) => theme.color.grey.shade8};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

export const GreyLine = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.grey.shade8};
`

export const ColoredInfoBox = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.blue.shade10};
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.color.blue.shade1};
  display: flex;
`

export const InfoHeader = styled(P)`
  color: ${({ theme }) => theme.color.blue.shade1};
`

export const InfoText = styled(Span)`
  color: ${({ theme }) => theme.color.blue.shade1};
`

export const ActionWrapper = styled.div`
  margin-top: 8px;
`

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

export const PayoutActionContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`
export const WhiteBoxBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const WhiteBoxBottomContainerAB = styled(WhiteBoxBottomContainer)`
  padding: 16px 16px 0 16px;
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey.shade8};
  margin: 8px 0;
`

export const DetailContainer = styled.div`
  background-color: ${({ theme, darker }) =>
    darker ? theme.color.grey.shade9 : theme.color.grey.shade10};
  padding: 8px 16px;
  flexgrow: 1;
`

export const DetailNumber = styled(P)`
  margin-right: 34px;
  text-wrap: nowrap;
  alignself: center;
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 48px;
  align-items: center;
`

export const TipsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { P, ICONS, Icon } from '@sellpy/design-system-react-web'
import colorTheme from '../color/theme.json'

const ExpandableSection = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
`

export const ExpandedContent = styled.div`
  transition: all 0.3 ease;
  overflow: hidden;
  padding: 0.5rem 0 1rem 0;
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
`

const ExpandableList = ({
  header,
  style,
  children,
  color = 'blue',
  className,
  startAsExpanded = false
}) => {
  const [expanded, setExpanded] = useState(startAsExpanded)
  return (
    <ExpandableSection className={className} style={{ ...style }}>
      <HeaderContainer onClick={() => setExpanded(!expanded)}>
        <P design='navSmall' color={color} noMargin>
          {header}
        </P>
        <Icon name={expanded ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN} style={{ color }} />
      </HeaderContainer>
      <ExpandedContent expanded={expanded}>
        {React.Children.map(children, (child) => child)}
      </ExpandedContent>
    </ExpandableSection>
  )
}

ExpandableList.propTypes = {
  header: PropTypes.string.isRequired,
  style: PropTypes.object,
  children: PropTypes.object.isRequired,
  color: PropTypes.oneOf(Object.keys(colorTheme))
}

export default ExpandableList

import { marketplace, money } from '@sellpy/commons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { P } from '@sellpy/design-system-react-web'
import { region } from '../../../common/region/region'
import { Divider } from '../../uiComponents'
import { calculateSelectedContainersPrices, calculateTotalAmount } from '../utils'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ContainerOrderCheckoutAmountElement = ({
  adyenAmount,
  creditAmount,
  nrOfBags,
  promoCodeId,
  containerPrice
}) => {
  const promoCode = useSelector((state) => promoCodeId && state.entities.PromoCode.get(promoCodeId))
  const { t } = useTranslation('containerOrder')

  const totalAmount = calculateTotalAmount({
    containerPrice,
    promoCode,
    nrOfBags
  })

  const selectedContainersPrices = calculateSelectedContainersPrices({
    containerPrice,
    promoCode,
    nrOfBags
  })

  const freeContainersCount = selectedContainersPrices.filter(({ amount }) => amount === 0).length
  const paidContainersCount = selectedContainersPrices.filter(({ amount }) => amount > 0).length

  return (
    <Wrapper>
      {freeContainersCount > 0 && (
        <FreeContainerRow count={freeContainersCount} t={t} promoCode={promoCode} />
      )}
      {paidContainersCount > 0 && (
        <PaidContainerRow
          containerPrice={containerPrice}
          count={paidContainersCount}
          totalAmount={totalAmount}
          t={t}
        />
      )}
      <CreditAmountRow creditAmount={creditAmount} t={t} />
      <Divider withBorder={true} />
      <AdyenAmountRow adyenAmount={adyenAmount} t={t} />
    </Wrapper>
  )
}

const DescriptionP = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
  margin-left: 4px;
`

const PaidContainerRow = ({ containerPrice, totalAmount, count, t }) => (
  <TextWrapper>
    <div style={{ display: 'flex' }}>
      <P design='body3' noMargin>
        {t('adyenSessions.nrOfContainers', { count })}
      </P>
      <DescriptionP design='body3' noMargin>
        {`(à ${money.formatSubUnit(containerPrice)})`}
      </DescriptionP>
    </div>
    <P design='body3' noMargin>
      {money.formatSubUnit(totalAmount)}
    </P>
  </TextWrapper>
)

const _zeroMoney = (currency) => ({
  amount: 0,
  currency
})

const FreeContainerRow = ({ count, promoCode, t }) => (
  <TextWrapper>
    <div style={{ display: 'flex' }}>
      <P design='body3' noMargin>
        {t('adyenSessions.nrOfContainers', { count })}
      </P>
      {promoCode && (
        <DescriptionP design='body3' noMargin>
          {`(${promoCode.get('code').toUpperCase()})`}
        </DescriptionP>
      )}
    </div>
    <P design='body3' noMargin>
      {money.formatBaseUnit(_zeroMoney(marketplace.CURRENCY[region()]))}
    </P>
  </TextWrapper>
)

const DiscountP = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
`

const CreditAmountRow = ({ creditAmount, t }) => {
  return creditAmount.amount > 0 ? (
    <TextWrapper style={{ marginTop: '8px' }}>
      <P design={'body3'}>{t('adyenSessions.amountElement.creditAmount')}</P>
      <DiscountP design={'body3'}>{`- ${money.formatSubUnit(creditAmount)}`}</DiscountP>
    </TextWrapper>
  ) : null
}

const AdyenAmountRow = ({ adyenAmount, t }) =>
  adyenAmount.amount ? (
    <TextWrapper>
      <P design={'body2'}>{t('adyenSessions.amountElement.totalAmount')}</P>
      <P design={'body2'}>{money.formatSubUnit(adyenAmount)}</P>
    </TextWrapper>
  ) : null

export default ContainerOrderCheckoutAmountElement

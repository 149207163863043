import { Map, Record, OrderedSet } from 'immutable'
import { parseErrorToErrorObject } from '../error'
import { clearCloudCache } from '../lib/parseTools'
import * as actions from './actions'

const InitialState = Record({
  facebookError: undefined,
  facebookLoading: false,
  setUserProfileError: undefined,
  updatePasswordError: undefined,
  showProfileForm: false,
  parseUser: undefined,
  passwordReset: Map({ status: 0, time: undefined }),
  favorites: OrderedSet(),
  favoritesLoading: false,
  passwordlessLoginError: undefined,
  dataUsageConsent: Map(),
  dataUsageConsentLoaded: false,
  isFirstTimeMarketCustomer: false,
  cityCoverage: undefined,
  userRoles: undefined,
  hasPreviousCreditPayout: true
})

const reinitializeUserState = (state) => (parseUser) =>
  state
    .set('facebookError', undefined)
    .set('facebookLoading', false)
    .set('parseUser', parseUser)
    .set('passwordlessLoginError', null)

const initialState = new InitialState()

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USER_DISCARD_FACEBOOK_ERROR: {
      return state.set('facebookError', null)
    }
    case actions.USER_FACEBOOK_LOGIN_LOADING: {
      return state.set('facebookError', null).set('facebookLoading', true)
    }
    case actions.USER_FACEBOOK_LOGIN_ERROR: {
      return state.set('facebookError', action.payload).set('facebookLoading', false)
    }

    case actions.SET_USER_PROFILE_SUCCESS:
      return state.set('parseUser', action.payload)
    case actions.SET_USER_PROFILE_ERROR:
      return state.set('setUserProfileError', action.payload)

    case actions.USER_ALREADY_LOGGED_IN_LOADING: {
      const parseUser = action.payload
      if (!state.parseUser && parseUser) {
        const stateWithUser = state.set('parseUser', parseUser)
        return stateWithUser
      } else {
        return state
      }
    }

    case actions.ENABLE_PROFILE_FORM: {
      return state.set('showProfileForm', true)
    }
    case actions.DISABLE_PROFILE_FORM: {
      return state.set('showProfileForm', false)
    }

    case actions.SET_USER_ADDRESS_SUCCESS:
    case actions.USER_ALREADY_LOGGED_IN_SUCCESS:
    case actions.UPDATE_PASSWORD_SUCCESS: {
      const parseUser = action.payload
      return reinitializeUserState(state)(parseUser)
    }
    case actions.USER_LOGGED_IN_WITH_SESSION_TOKEN:
    case actions.USER_PASSWORDLESS_LOGIN_SUCCESS:
    case actions.USER_ANONYMOUS_SIGNUP_SUCCESS:
    case actions.USER_FACEBOOK_LOGIN_SUCCESS:
    case actions.USER_PARSE_LOGIN_SUCCESS:
    case actions.USER_PARSE_SIGNUP_SUCCESS: {
      clearCloudCache()
      const parseUser = action.payload
      return reinitializeUserState(state)(parseUser)
    }
    case actions.USER_PASSWORDLESS_LOGIN_LOADING: {
      return state.set('passwordlessLoginError', null)
    }
    case actions.USER_PASSWORDLESS_LOGIN_ERROR: {
      return state.set('passwordlessLoginError', parseErrorToErrorObject(action.error))
    }
    case actions.DATA_USAGE_CONSENT_RETRIEVED: {
      return state
        .update('dataUsageConsent', (consentMap) => consentMap.merge(action.consentMap))
        .set('dataUsageConsentLoaded', true)
    }
    case actions.DATA_USAGE_CONSENT_SET: {
      return state.setIn(['dataUsageConsent', action.dataType], action.consent)
    }
    case actions.IS_FIRST_TIME_MARKET_CUSTOMER_SUCCESS: {
      return state.set('isFirstTimeMarketCustomer', action.isFirstTimeMarketCustomer)
    }
    case actions.USER_BELONGS_TO_ROLES_LOADING: {
      return state.set('userRoles', Map())
    }
    case actions.USER_BELONGS_TO_ROLES_SUCCESS: {
      return state.set(
        'userRoles',
        action.roles.reduce(
          (newState, role) => newState.set(role.name, role.belonging),
          state.get('userRoles')
        )
      )
    }
    case actions.USER_BELONGS_TO_ROLES_RESET: {
      return state.set('userRoles', Map())
    }
  }

  return state
}

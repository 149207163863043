import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { P, ICONS, Icon } from '@sellpy/design-system-react-web'
import { analyticsAccordionClicked } from '../../../common/analytics/actions'

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAccordionTitle = styled.div``

export const StyledAccordionHeader = styled.div`
  background-color: transparent;
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  justify-content: space-between;
  border-bottom: ${({ active, size, theme }) =>
    size === 'small'
      ? active
        ? '0px'
        : `1px solid ${theme.color.grey.shade7}`
      : '1px solid black'};
`

export const AccordionContent = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  overflow: hidden;
  transition: max-height 0.2s ease;
  border-bottom: ${({ size, theme }) =>
    size === 'small' ? `1px solid ${theme.color.grey.shade7}` : '0px'};
  padding-bottom: ${({ size }) => size === 'small' && '1rem'};
`

/**
 * @deprecated
 */
const Accordion = ({
  title,
  subTitle,
  content,
  size = 'medium',
  headerComponent,
  className,
  id,
  initiallyActive = false
}) => {
  const [active, setActive] = useState(initiallyActive)

  const toggleAccordion = () => {
    setActive(!active && true)
  }

  return (
    <AccordionWrapper className={className}>
      <AccordionHeader>
        <StyledAccordionHeader
          active={active}
          onClick={() => {
            toggleAccordion()
            id && analyticsAccordionClicked(id)
          }}
          size={size}
        >
          <AccordionTitle
            headerComponent={headerComponent}
            size={size}
            title={title}
            subTitle={subTitle}
          />
          <Icon
            name={active ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
            style={{ fontSize: '2rem' }}
          />
        </StyledAccordionHeader>
      </AccordionHeader>
      <AccordionContent size={size} active={active}>
        <div>{content}</div>
      </AccordionContent>
    </AccordionWrapper>
  )
}

export const AccordionHeader = ({ children }) => {
  return <>{children}</>
}

export const AccordionTitle = ({ headerComponent, size, title, subTitle }) => {
  const theme = useTheme()
  const HeaderComponent = headerComponent
  return (
    <StyledAccordionTitle>
      {headerComponent ? (
        <HeaderComponent design={size === 'small' ? 'body2' : 'h3'} noMargin>
          {title}
        </HeaderComponent>
      ) : (
        <P design={size === 'small' ? 'body2' : 'h3'} noMargin>
          {title}
        </P>
      )}
      {subTitle && (
        <P design='body5' noMargin style={{ color: theme.color.grey.shade3 }}>
          {subTitle}
        </P>
      )}
    </StyledAccordionTitle>
  )
}

export default Accordion

/* global fetch */
import { fromJS, Map, Set, List } from 'immutable'
import config from 'config'
import { parseTransformer, searchStateTools, user as userTools } from '@sellpy/commons'
import { runCloudCached, parseObject } from '../lib/parseTools'
import Parse from '../../client/lib/parse'
import { algoliaSearch, INIT_LOCALIZED_ITEM_INDEX } from '../lib/algolia'
import { cacheEntities } from '../entities/actions'
import { showToast } from '../ui/actions'
import { TOAST_TYPE } from '../../client/uiComponents/notifications/Toasts.jsx'
import { currentUserSelector } from '../user/selectors'
import { analyticsItemMapper } from '../analytics/mappers'
import { getCurrentLoyaltyProgramPeriod } from '../entities/selectors/loyaltyProgramPeriod'
import { getCurrentPeriodForUser } from '../loyalty/actions'
import { isLoyaltyEnabled } from '../region/region'
import { cachePricingModel } from '../cart/actions.js'
import { getIsBusinessSaleSelector } from './reducer'
import { itemPaymentsSelector, itemValuesSelector } from './selectors'
export const ITEM_LOADING = 'ITEM_LOADING'
export const ITEM_SUCCESS = 'ITEM_SUCCESS'
export const ITEM_ERROR = 'ITEM_ERROR'

export function getItem(itemId) {
  return (dispatch) => {
    dispatch({ type: ITEM_LOADING })
    return Parse.Cloud.run('getPdpItem', { itemId })
      .then((item) => {
        dispatch(cacheEntities(item))
        dispatch({ type: ITEM_SUCCESS })
        return parseTransformer.normalize(item).getIn(['Item', itemId])
      })
      .catch((error) => {
        dispatch({ type: ITEM_ERROR, payload: error })
        throw error
      })
  }
}

export const getContainerById = (containerId) => {
  return async (dispatch) => {
    return runCloudCached((container) => {
      dispatch(cacheEntities(container))
    })('getContainerById_noRequiredRole', { containerId }).catch((error) => {
      console.error(error)
    })
  }
}

export const GET_MARKET_OFFER_LOADING = 'GET_MARKET_OFFER_LOADING'
export const GET_MARKET_OFFER_SUCCESS = 'GET_MARKET_OFFER_SUCCESS'
export const GET_MARKET_OFFER_ERROR = 'GET_MARKET_OFFER_ERROR'

export const getMarketOffers = (itemId) => async (dispatch) => {
  dispatch({ type: GET_MARKET_OFFER_LOADING })
  return Parse.Cloud.run('getMarketOffersForItem', { itemId, region: process.env.REGION })
    .then((marketOffers) => {
      dispatch(cacheEntities(marketOffers))
      dispatch({ type: GET_MARKET_OFFER_SUCCESS })
    })
    .catch((error) => {
      console.error(error)
      dispatch({ type: GET_MARKET_OFFER_ERROR })
    })
}

export const GET_MARKET_FIRST_INVENTORY_LOADING = 'GET_MARKET_FIRST_INVENTORY_LOADING'
export const GET_MARKET_FIRST_INVENTORY_SUCCESS = 'GET_MARKET_FIRST_INVENTORY_SUCCESS'
export const GET_MARKET_FIRST_INVENTORY_ERROR = 'GET_MARKET_FIRST_INVENTORY_ERROR'

export const getLatestMarketFirstInventory = (itemId) => async (dispatch) => {
  dispatch({ type: GET_MARKET_FIRST_INVENTORY_LOADING })
  return runCloudCached((marketInventory) => {
    dispatch(cacheEntities(marketInventory))
    dispatch({ type: GET_MARKET_FIRST_INVENTORY_SUCCESS })
  })('getLatestMarketFirstInventory', { itemId }).catch((error) => {
    console.error(error)
    dispatch({ type: GET_MARKET_FIRST_INVENTORY_ERROR })
  })
}

export const getLatestMarketInventoryForItem = (itemId) => async (dispatch) => {
  dispatch({ type: GET_MARKET_FIRST_INVENTORY_LOADING })
  return runCloudCached((marketInventory) => {
    dispatch(cacheEntities(marketInventory))
  })('getLatestMarketInventoryForItem', { itemId })
}

export const SALE_HISTORY_LOADING = 'SALE_HISTORY_LOADING'
export const SALE_HISTORY_SUCCESS = 'SALE_HISTORY_SUCCESS'

export const getSaleHistoryForOwnItem = (itemId) => {
  return async (dispatch) => {
    dispatch({ type: SALE_HISTORY_LOADING })
    const saleHistory = await Parse.Cloud.run('getSaleHistoryForOwnItem', { itemId })
    if (saleHistory) {
      dispatch({
        type: SALE_HISTORY_SUCCESS,
        saleHistory: fromJS({ [itemId]: saleHistory.saleHistory })
      })
      dispatch(cacheEntities(saleHistory.claim))
      dispatch(cacheEntities(saleHistory.orders))
    }
  }
}

export const SALE_FUTURE_LOADING = 'SALE_FUTURE_LOADING'
export const SALE_FUTURE_SUCCESS = 'SALE_FUTURE_SUCCESS'

export const getSaleFutureForOwnItem = (itemId) => {
  return async (dispatch) => {
    dispatch({ type: SALE_FUTURE_LOADING })
    const saleFuture = await Parse.Cloud.run('getSaleFutureForOwnItem', { itemId })
    dispatch({
      type: SALE_FUTURE_SUCCESS,
      saleFuture: fromJS({ [itemId]: saleFuture })
    })
  }
}

export const getSaleRequest = (itemId) => async (dispatch) => {
  return runCloudCached((saleRequest) => {
    dispatch(cacheEntities(saleRequest))
  })('getSaleRequestForItem', { itemId }).catch(console.error)
}

export const getLastPricingPeriod = (itemId) => async (dispatch) => {
  const lastPricingPeriod = await Parse.Cloud.run('getLastPricingPeriodForItem', { itemId })
  dispatch(cacheEntities(lastPricingPeriod))
}

const whereWasItemSoldPromises = {}

export const WHERE_WAS_ITEM_SOLD_SUCCESS = 'WHERE_WAS_ITEM_SOLD_SUCCESS'
export const WHERE_WAS_ITEM_SOLD_ERROR = 'WHERE_WAS_ITEM_SOLD_ERROR'

const fetchWhereWasItemSold = (itemId) => async (dispatch) => {
  return runCloudCached((offer) => {
    dispatch(cacheEntities(offer))
    dispatch({
      type: WHERE_WAS_ITEM_SOLD_SUCCESS,
      itemId,
      offerName: offer.className,
      offerId: offer.id
    })
  })('whereWasItemSold', { itemId }).catch((error) => {
    console.error(error)
    dispatch({ type: WHERE_WAS_ITEM_SOLD_ERROR, itemId })
    return Promise.reject(error)
  })
}

export const whereWasItemSold = (itemId) => {
  return (dispatch, getState) => {
    const cachedOffer = getState().items.whereWasItemSold.get(itemId)
    if (cachedOffer || cachedOffer === null) return Promise.resolve(cachedOffer)
    if (whereWasItemSoldPromises[itemId]) return whereWasItemSoldPromises[itemId]
    whereWasItemSoldPromises[itemId] = dispatch(fetchWhereWasItemSold(itemId))
    return whereWasItemSoldPromises[itemId]
  }
}

export const ALGOLIA_ITEMS_SUCCESS = 'ALGOLIA_ITEMS_SUCCESS'

export const getAlgoliaItems = (itemIds, index = INIT_LOCALIZED_ITEM_INDEX) => async (
  dispatch,
  getState
) => {
  if (!(itemIds instanceof Set)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('itemIds needs to be Immutable.Set')
  } else if (!itemIds.size) return Promise.resolve([])

  const user = getState().user.parseUser
  const loggedIn = userTools.userIsFull(user)
  if (loggedIn) await dispatch(getCurrentPeriodForUser({ region: process.env.REGION }))

  const firstAccessEligibility = !isLoyaltyEnabled || !!getCurrentLoyaltyProgramPeriod(getState())
  const userId = currentUserSelector(getState())
  const { hits } = await index.search('', {
    filters: searchStateTools.composeDefaultAlgoliaFilters({
      firstAccessEligibility,
      sellerUserId: userId,
      region: process.env.REGION
    }),
    facetFilters: [itemIds.map((itemId) => `objectID:${itemId}`).toJS()],
    hitsPerPage: 1000
  })
  dispatch({ type: ALGOLIA_ITEMS_SUCCESS, hits })
  return hits
}

export const getAnalyticsItem = ({ itemId }) => async (dispatch, getState) => {
  let analyticsItem = ((state) => state.items.algoliaItems.get(itemId))(getState())
  const metadata = analyticsItem?.toJS ? analyticsItem.get('metadata') : analyticsItem?.metadata
  if (!metadata) {
    const algoliaItem = await dispatch(getAlgoliaItems(Set([itemId])))
    analyticsItem = algoliaItem.find((item) => item.objectID === itemId)
  }
  return analyticsItemMapper(analyticsItem)
}

export const GET_ITEM_PRICE_ESTIMATE = 'GET_ITEM_PRICE_ESTIMATE'

export const getItemPriceEstimate = (itemId) => (dispatch) => {
  if (!itemId) return
  return runCloudCached((priceEstimate) =>
    dispatch({
      type: GET_ITEM_PRICE_ESTIMATE,
      priceEstimate: fromJS(priceEstimate)
    })
  )('itemPriceEstimate', { itemId, region: process.env.REGION })
}

export const GET_SIMILAR_ITEMS_LOADING = 'GET_SIMILAR_ITEMS_LOADING'
export const GET_SIMILAR_ITEMS_SUCCESS = 'GET_SIMILAR_ITEMS_SUCCESS'
export const GET_SIMILAR_ITEMS_ERROR = 'GET_SIMILAR_ITEMS_ERROR'

export function getSimilarItems(itemIds) {
  return (dispatch, getState) => {
    dispatch({ type: GET_SIMILAR_ITEMS_LOADING })
    // Don't refetch similar items
    itemIds = itemIds.filter((itemId) => !getState().items.similarItems.has(itemId))
    if (!itemIds.size && !itemIds.length) return
    return fetchSimilarItems({ itemIds })
      .then((similarItems) => {
        const similarItemsMap = fromJS(similarItems).filter((item) => !item.get('isReserved'))

        const allItemIds = similarItemsMap.reduce(
          (allIds, similarIds) => allIds.union(similarIds),
          Set()
        )

        dispatch(getAlgoliaItems(allItemIds)).then(() => {
          dispatch({
            type: GET_SIMILAR_ITEMS_SUCCESS,
            itemIds: similarItemsMap
          })
        })
      })
      .catch((error) => console.warn('getSimilarItems error', error))
  }
}

export const NR_SIMILAR_ITEMS_TO_FETCH = 20

const fetchSimilarItems = async ({ itemIds }) => {
  return fetch(config.ml.similarItems.url, {
    method: 'POST',
    headers: {
      'X-SellpySimilarItems-Key': config.ml.similarItems.key,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      objectIds: itemIds,
      region: process.env.REGION,
      nItems: NR_SIMILAR_ITEMS_TO_FETCH
    })
  }).then((response) => response.json())
}

export const GET_SIMILAR_BRANDS_LOADING = 'GET_SIMILAR_BRANDS_LOADING'
export const GET_SIMILAR_BRANDS_SUCCESS = 'GET_SIMILAR_BRANDS_SUCCESS'

export const getSimilarBrands = (brand) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_SIMILAR_BRANDS_LOADING })
    if (getState().items.similarBrands.has(brand) || !brand) return
    return fetchSimilarBrands(brand)
      .then((data) => {
        if (!data.result.brand) return
        INIT_LOCALIZED_ITEM_INDEX.search('', {
          query: '',
          facetFilters: [data.result.brand.map((brand) => `metadata.brand:${brand}`)],
          facets: ['metadata.brand'],
          attributesToRetrieve: ''
        }).then((res) => {
          const filteredArray = data.result.brand.filter(
            (brand) => Map(res.facets['metadata.brand']).get(brand) > 0
          )
          dispatch({
            type: GET_SIMILAR_BRANDS_SUCCESS,
            brands: Map().set(brand, List(filteredArray))
          })
        })
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const NR_SIMILAR_BRANDS = 20
// Endpoint needs to supply Access-Control-Allow-Origin
export const fetchSimilarBrands = (brand) => {
  return fetch(config.algolia.similarBrands, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      request: {
        brand: {
          n: NR_SIMILAR_BRANDS,
          value: brand
        }
      }
    })
  })
    .then((response) => response.json())
    .catch((error) => {
      if (error.message === 'Failed to fetch') return { result: {} }
      else return Promise.reject(error)
    })
}

export const createSellerReturnReservation = (itemId, t) => {
  return (dispatch) => {
    return Parse.Cloud.run('createSellerReturnReservation', { itemId })
      .then(({ item, sale, reservation, pricingModel }) => {
        dispatch(cachePricingModel({ sale, pricingModel }))
        dispatch(cacheEntities(item))
        dispatch(cacheEntities(sale))
        dispatch(cacheEntities(reservation))
        dispatch(getSaleHistoryForOwnItem(item.id))
        dispatch(
          showToast({
            header: t('sellerReturn.confirmToast.header'),
            body: t('sellerReturn.confirmToast.body'),
            type: TOAST_TYPE.SUCCESS
          })
        )
      })
      .catch((error) => {
        dispatch(
          showToast({
            header: t('common:errorToast.header'),
            body: t('common:errorToast.body'),
            type: TOAST_TYPE.ERROR
          })
        )
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const removeSellerReturnReservation = (reservationId) => {
  return (dispatch) => {
    return Parse.Cloud.run('removeSellerReturnReservation', { reservationId })
      .then(({ item, reservation }) => {
        dispatch(cacheEntities(item))
        dispatch(cacheEntities(reservation))
        dispatch(getSaleHistoryForOwnItem(item.id))
      })
      .catch(console.error)
  }
}

export const fetchItemIO = (itemIOId) => (dispatch, getState) => {
  if (!itemIOId) return
  if (getState().entities.ItemIO.get(itemIOId)) return Promise.resolve()
  return runCloudCached((itemIO) => {
    dispatch(cacheEntities(itemIO))
    return itemIO
  })('fetchItemIO', { itemIOId }).catch(console.error)
}

export const SEND_ITEM_FEEDBACK_LOADING = 'SEND_ITEM_FEEDBACK_LOADING'
export const SEND_ITEM_FEEDBACK_SUCCESS = 'SEND_ITEM_FEEDBACK_SUCCESS'

export const sendItemFeedback = (objectId, itemId, reason, subReason, message) => {
  return (dispatch) => {
    dispatch({ type: SEND_ITEM_FEEDBACK_LOADING })
    if (objectId) {
      return Parse.Cloud.run('updateItemFeedback', {
        objectId,
        itemId,
        reason,
        subReason,
        message
      }).then((feedback) => {
        dispatch({ type: SEND_ITEM_FEEDBACK_SUCCESS })
        dispatch(cacheEntities(feedback))
        return feedback
      })
    } else {
      return Parse.Cloud.run('createItemFeedback', {
        objectId,
        itemId,
        reason,
        subReason,
        message
      }).then((feedback) => {
        dispatch({ type: SEND_ITEM_FEEDBACK_SUCCESS })
        dispatch(cacheEntities(feedback))
        return feedback
      })
    }
  }
}

export const GET_FEEDBACK_LOADING = 'GET_FEEDBACK_LOADING'
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS'

export const getItemFeedback = (itemId) => {
  return (dispatch) => {
    return Parse.Cloud.run('getItemFeedback', { itemId }).then((feedback) => {
      dispatch(cacheEntities(feedback))
      return feedback
    })
  }
}

export const fetchItemIOFromType = ({ forType, locale }) => async (dispatch) => {
  return runCloudCached((itemIO) => {
    if (itemIO) {
      dispatch(cacheEntities(itemIO))
      return itemIO
    }
  })('fetchItemIOFromType', { forType, locale }).catch(console.error)
}

export const FETCH_BRANDS = 'FETCH_BRANDS'

export const getBrands = (query) => {
  return async (dispatch) => {
    const results = await algoliaSearch('brand', query, { hitsPerPage: 1000 })
    dispatch({ type: FETCH_BRANDS, brands: fromJS(results) })
  }
}

export const FETCH_TYPES = 'FETCH_TYPES'

export const getTypes = (locale) => (query) => {
  return async (dispatch) => {
    const results = await algoliaSearch(`itemType_${locale}`, query, { hitsPerPage: 1000 })
    dispatch({ type: FETCH_TYPES, itemTypes: fromJS(results) })
  }
}

export const getLatestItemValuation = (itemId) => async (dispatch) => {
  const itemValuation = await Parse.Cloud.run('getLatestItemValuation', { itemId })
  dispatch(cacheEntities(itemValuation))
  return itemValuation
}

export const getOwnOrderForItem = (itemId) => async (dispatch) => {
  // TODO: update to runCloudCached function. Requires a new cloud function in parsoku that performs this query
  const order = await new Parse.Query('Order')
    .equalTo('items', parseObject('Item', itemId))
    .equalTo('user', Parse.User.current())
    .descending('createdAt')
    .notEqualTo('status', 'canceled')
    .include('items')
    .first()
  dispatch(cacheEntities(order))
}

export const getP2pOrderForItem = (itemId) => async (dispatch) => {
  const order = await Parse.Cloud.run('getP2pOrderForItem', { itemId })
  dispatch(cacheEntities(order))
  return order
}

export const fetchSucceedingItemCopy = (itemId) => (dispatch) =>
  runCloudCached((succeedingItem) => dispatch(cacheEntities(succeedingItem)))(
    'fetchSucceedingItemCopy',
    {
      itemId
    }
  )

export const p2pRequestItem = ({ itemId, priceSuggestion }) => async (dispatch) => {
  const buyRequest = await Parse.Cloud.run('p2pRequestItem', {
    itemId,
    priceSuggestion,
    region: process.env.REGION
  })
  dispatch(cacheEntities(buyRequest))
}

export const getP2pRequestsForItem = (itemId) => async (dispatch) => {
  const buyRequests = await Parse.Cloud.run('getP2pRequestsForItem', { itemId })
  dispatch(cacheEntities(buyRequests))
}

export const declineP2pBuyRequests = (itemId) => async (dispatch) => {
  const declinedBuyRequests = await Parse.Cloud.run('declineP2pBuyRequestsForItem', { itemId })
  dispatch(cacheEntities(declinedBuyRequests))
}

export const CAN_BE_REQUESTED_P2P = 'CAN_BE_REQUESTED_P2P'
export const CANNOT_BE_REQUESTED_P2P = 'CANNOT_BE_REQUESTED_P2P'

export const getCanBeRequestedP2P = (itemId) => async (dispatch, getState) => {
  const exists = getState().items.getIn(['canBeRequestedP2P', itemId])
  if (exists === undefined) {
    try {
      const canBeSold = await Parse.Cloud.run('getCanBeRequestedP2P', { itemId })
      if (canBeSold) dispatch({ type: CAN_BE_REQUESTED_P2P, itemId })
      else dispatch({ type: CANNOT_BE_REQUESTED_P2P, itemId })
      return canBeSold
    } catch (_) {
      dispatch({ type: CANNOT_BE_REQUESTED_P2P, itemId })
    }
  } else {
    return exists
  }
}

export const IS_BUSINESS_SALES = 'IS_BUSINESS_SALES'

export const getIsBusinessSales = (items) => async (dispatch, getState) => {
  const isBusinessSaleByItem = getIsBusinessSaleSelector(getState(), items)
  const itemsToFetch = items.filter(
    (item) => isBusinessSaleByItem.get(item.get('objectId')) === undefined
  )
  if (itemsToFetch.length === 0) return
  dispatch({
    type: IS_BUSINESS_SALES,
    isBusinessSalesMap: itemsToFetch.reduce((map, item) => map.set(item.get('bag'), null), Map())
  })
  Parse.Cloud.run('getIsBusinessSales', {
    itemIds: itemsToFetch.map((item) => item.get('objectId'))
  }).then((isBusinessSalesMap) =>
    dispatch({ type: IS_BUSINESS_SALES, isBusinessSalesMap: fromJS(isBusinessSalesMap) })
  )
}

export const toggleOptOutP2pItem = (itemId) => async (dispatch) => {
  const item = Parse.Cloud.run('toggleOptOutP2pItem', { itemId })
  dispatch(cacheEntities(item))
  return item
}

export const FETCH_EXPECTED_SHIPPING_DATES = 'FETCH_EXPECTED_SHIPPING_DATES'

export const getExpectedShippingDates = (itemIds) => async (dispatch) => {
  return Parse.Cloud.run('getExpectedShippingDates', { itemIds }).then((dates) =>
    dispatch({ type: FETCH_EXPECTED_SHIPPING_DATES, dates })
  )
}

export const adjustPreparedItemSale = (marketInventoryId, value) => (dispatch) =>
  Parse.Cloud.run('adjustPreparedItemSale', { marketInventoryId, value }).then(
    (marketInventory) => {
      dispatch(cacheEntities(marketInventory))
      dispatch(getItemValue(marketInventory?.get('item')?.id, { cacheBust: true }))
    }
  )

export const commencePreparedItemSale = (marketInventoryId) => (dispatch) =>
  Parse.Cloud.run('commencePreparedItemSale', { marketInventoryId })
    .then((marketInventory) => {
      dispatch(cacheEntities(marketInventory))
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })

export const getAllActiveOffersForItem = (itemId) => async (dispatch) => {
  const marketOffers = await Parse.Cloud.run('getAllActiveOffersForItem', { itemId }).catch(
    (error) => {
      console.error(error)
      return Promise.reject(error)
    }
  )

  marketOffers.filter(Boolean).forEach((offers) => {
    return offers.length > 0 && offers.forEach((offer) => dispatch(cacheEntities(offer)))
  })
}

export const fetchItemChangeRequests = (itemId) => async (dispatch) => {
  dispatch(cacheEntities(await Parse.Cloud.run('fetchItemChangeRequests', { itemId })))
}

export const ITEMS_PREVIOUSLY_CART = 'ITEMS_PREVIOUSLY_CART'

export const getItemsPreviouslyInCart = () => (dispatch) => {
  if (Parse.User.current()) {
    return Parse.Cloud.run('getPreviouslyInCart').then((items) =>
      dispatch({ type: ITEMS_PREVIOUSLY_CART, items: fromJS(items) })
    )
  }
}

export const ITEM_VALUES = 'ITEM_VALUES'

export const getItemValues = (itemIds) => async (dispatch, getState) => {
  itemIds = itemIds.filter(Boolean)
  if (itemValuesSelector(itemIds)(getState()).every((value) => value !== undefined)) return
  // Set values to null to indicate fetching has started and prevent duplicate fetches for the same items
  dispatch({
    type: ITEM_VALUES,
    itemValues: itemIds.reduce((map, itemId) => map.set(itemId, null), Map())
  })
  const itemValues = await Parse.Cloud.run('getItemValues', { itemIds })
  dispatch({
    type: ITEM_VALUES,
    itemValues: fromJS(itemValues)
  })
}

export const getItemValue = (itemId, cacheOptions) => async (dispatch) => {
  return runCloudCached(
    (itemValues) =>
      dispatch({
        type: ITEM_VALUES,
        itemValues: fromJS(itemValues)
      }),
    cacheOptions
  )('getItemValues', {
    itemIds: [itemId]
  })
}

export const cacheItemValue = ({ itemId, value }) => {
  return {
    type: ITEM_VALUES,
    itemValues: fromJS({ [itemId]: value })
  }
}

export const ITEM_PAYMENTS = 'ITEM_PAYMENTS'

export const getItemPayments = (itemIds) => async (dispatch, getState) => {
  itemIds = itemIds.filter(Boolean)
  if (itemPaymentsSelector(itemIds)(getState()).every((payment) => payment !== undefined)) return
  // Set values to null to indicate fetching has started and prevent duplicate fetches for the same itemIds
  dispatch({
    type: ITEM_PAYMENTS,
    itemPayments: itemIds.reduce((map, itemId) => map.set(itemId, null), Map())
  })
  const itemPayments = await Parse.Cloud.run('getItemPayments', { itemIds })
  dispatch({
    type: ITEM_PAYMENTS,
    itemPayments: fromJS(itemPayments)
  })
}

export const getItemsForSale = () => async (dispatch) => {
  return runCloudCached((items) => dispatch(cacheEntities(items)))('getItemsForSale')
}

export const getSendBackRequestForItem = (itemId) => async (dispatch) => {
  return runCloudCached((sendBackRequest) => {
    dispatch(cacheEntities(sendBackRequest))
  })('getSendBackRequestForItem', { itemId }).catch(console.error)
}

export const createSendBackRequestForItem = ({ itemId }) => async (dispatch) => {
  return Parse.Cloud.run('createSendBackRequestForItem', { itemId })
    .then((sendBackRequest) => dispatch(cacheEntities(sendBackRequest)))
    .catch(console.error)
}

export const commencePreparedItemSales = ({ saleId, commenceAllAwaitingApproval }) => async (
  dispatch
) => {
  const items = await Parse.Cloud.run('commencePreparedItemSales', {
    saleId,
    commenceAllAwaitingApproval
  })
  // Used to update redux state for visual feedback for client.
  if (items) items.map((item) => item.set('itemStatus', 'utlagd'))
  dispatch(cacheEntities(items))
}

export const getTotalAwaitingApprovalsInSale = async ({ saleId }) =>
  Parse.Cloud.run('getTotalAwaitingApprovalsInSale', {
    saleId
  })

export const getTotalAwaitingApprovalsForUser = async () =>
  Parse.Cloud.run('getTotalAwaitingApprovalsForUser')

export const getDonateAfter = async (itemId) => {
  return runCloudCached()('getDonateAfter', { itemId }).catch(console.error)
}

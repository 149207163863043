import React from 'react'
import { Trans } from 'react-i18next'
import { marketplace } from '@sellpy/commons'
import { P, A } from '@sellpy/design-system-react-web'
import { adyenPrivacyPolicyLink } from '../../../common/region/config'
import { region } from '../../../common/region/region'

const AdyenPolicy = ({ className }) => {
  return (
    <P noMargin design={'body5'} className={className}>
      <Trans i18nKey='cart:buyConditionsAdyen'>
        part1
        <A
          href={`https://www.adyen.com/${marketplace.LOCALE[region()]}_${region()}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Adyen
        </A>
        part2
        <A href={adyenPrivacyPolicyLink} target='_blank' rel='noopener noreferrer'>
          Adyens privacy policy
        </A>
        part3
      </Trans>
    </P>
  )
}

export default AdyenPolicy

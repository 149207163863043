import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { P } from '@sellpy/design-system-react-web'
import { textStyle } from '../../../uiComponents/text/styles'

const StyledParagraph = styled.span`
  ${textStyle}
  ${({ isOnlineBankingPL, theme }) =>
    isOnlineBankingPL &&
    `
      .adyen-checkout__dropdown__button {
          width: auto;
          height: 16px;
      }
      .adyen-checkout__button.adyen-checkout__button--pay {
        display: none;
      }
      .adyen-checkout-disclaimer__label {
        margin-top: 4px
      }
  `}
`

const TypeIcon = styled.span`
  margin-left: auto;
  background-repeat: no-repeat;
  padding-right: 8px;
  height: 24px;
  width: 40px;
  background-blend-mode: darken;
  background-color: ${({ theme, selected }) =>
    selected ? theme.color.blue.shade10 : theme.color.grey.shade9};
  background-image: ${({ type }) =>
    `url('https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${type}.svg')`};
  ${({ isOnlineBankingPL }) =>
    isOnlineBankingPL &&
    `margin-right: 8px;
    background-position: center;
  `}
`

// Determines colour of the radio button label.
const _defaultLabelColor = ({ disabled, theme }) =>
  disabled ? theme.color.grey.shade6 : theme.color.grey.shade2

// Determines colour of the radio button icon when filled, i.e. input is checked.
const _defaultFilledColor = ({ disabled, erroneous, theme }) =>
  disabled
    ? theme.color.grey.shade4
    : erroneous
    ? theme.color.red.default
    : theme.color.blue.default

const RadioButtonWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey.shade10 : theme.color.grey.shade9};
  border-radius: 8px;
  margin: 8px 0;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  align-items: stretch;
  min-height: 40px;
`

const StyledLabel = styled(P)`
  color: ${_defaultLabelColor};
  padding: 8px 0px 8px 0px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const StyledInput = styled.input.attrs({
  type: 'radio'
})`
  --icon-radio-button: '\\E8FE';
  --icon-radio-button-filled: '\\E8FD';
  appearance: none;
  border: none;
  font-size: 24px;
  line-height: 24px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-family: 'fontello';
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  color: ${({ erroneous, theme }) =>
    erroneous ? theme.color.red.default : theme.color.grey.shade6};

  ::before {
    /* Radio button icon */
    content: var(--icon-radio-button);
    position: relative;
    visibility: visible;
    top: 8px;
    padding: 8px;
  }

  &:checked {
    color: ${_defaultFilledColor};
    ::before {
      /* Radio button icon */
      content: var(--icon-radio-button-filled);
    }
  }
`

const StoredCardIcon = ({ expiryMonth, expiryYear, lastFour }) => {
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        paddingRight: '8px'
      }}
    >
      <span style={{ fontSize: '8px', lineHeight: '10px' }}>
        {expiryMonth}/{expiryYear}
      </span>
      <span style={{ fontSize: '8px', lineHeight: '10px' }}>●●●● {lastFour}</span>
    </span>
  )
}

export const getDetailedType = (component) =>
  component.props?.storedPaymentMethodId ? 'saved_card' : component.type

const SwishStyledParagraph = styled(StyledParagraph)`
  > div {
    margin: 0 8px 8px;
  }
`

// eslint-disable-next-line react/display-name
const AdyenRadioButton = React.forwardRef(
  ({ disabled, name, onChange, erroneous, refs, component, watch, onSelect }, ref) => {
    const { t } = useTranslation('adyenSessionPayment')
    const [showRef, setShowRef] = useState(null)
    const detailedType = getDetailedType(component)

    const isOnlineBankingPL = detailedType === 'onlineBanking_PL'

    const selectedAdyenOption = watch('adyenOptions')
    useEffect(() => {
      if (!showRef && selectedAdyenOption === component._id) {
        setShowRef(true)
        component.mount(refs[detailedType].current)
      }
      if (showRef && selectedAdyenOption !== component._id) {
        setShowRef(false)
      }
    }, [selectedAdyenOption, component, showRef, detailedType, refs])

    const handleChange = (...args) => {
      onChange(...args)
      onSelect()
    }

    return (
      <RadioButtonWrapper
        disabled={disabled}
        className={component._id === selectedAdyenOption ? 'selected_payment_option' : ''}
      >
        <span style={{ display: 'flex' }}>
          <StyledInput
            ref={ref}
            name={name}
            onChange={handleChange}
            value={component._id}
            disabled={disabled}
            erroneous={erroneous}
          />
          <StyledLabel design='body1' noMargin>
            {detailedType === 'saved_card'
              ? t('adyenOptions.saved_card.name')
              : component.props.name}
            {detailedType === 'saved_card' ? (
              <StoredCardIcon
                expiryMonth={component.props.expiryMonth}
                expiryYear={component.props.expiryYear}
                lastFour={component.props.lastFour}
              />
            ) : (
              <TypeIcon type={detailedType} isOnlineBankingPL={isOnlineBankingPL} />
            )}
          </StyledLabel>
        </span>
        {detailedType === 'swish' && (
          <SwishStyledParagraph
            noMargin
            design={'body4'}
            ref={refs[detailedType]}
            style={{
              display: !showRef ? 'none' : 'block'
            }}
          />
        )}
        {/*
         Hide the mobilepay button since it do the same as our payment button. 
        */}
        {detailedType === 'mobilepay' && (
          <StyledParagraph
            ref={refs[detailedType]}
            style={{
              display: 'none'
            }}
          />
        )}
        {!['paypal', 'swish', 'mobilepay'].includes(detailedType) && (
          <StyledParagraph
            noMargin
            design={'body4'}
            ref={refs[detailedType]}
            style={{
              margin:
                showRef &&
                ['card', 'saved_card', 'swish', 'ideal', 'bcmc', 'onlineBanking_PL'].includes(
                  detailedType
                )
                  ? '0 8px 8px 8px'
                  : '0px',
              display: !showRef ? 'none' : 'block'
            }}
            isOnlineBankingPL={isOnlineBankingPL}
          />
        )}
      </RadioButtonWrapper>
    )
  }
)

const AdyenOptions = ({
  refs,
  setValue,
  checkout,
  errors,
  register,
  isDirty,
  disabled,
  validate,
  watch,
  setSelectedPaymentMethodIndex,
  latestPaymentMethod
}) => {
  useEffect(() => {
    if (checkout?.components && !isDirty) {
      if (latestPaymentMethod) {
        const matchingComponent = checkout?.components.find((component) => {
          if (latestPaymentMethod?.storedPaymentMethodId) {
            return (
              component?.data?.paymentMethod?.storedPaymentMethodId ===
              latestPaymentMethod.storedPaymentMethodId
            )
          } else {
            return (
              component?.data?.paymentMethod?.type === latestPaymentMethod.type ||
              (latestPaymentMethod.brand &&
                component?.data?.paymentMethod?.brand === latestPaymentMethod.brand)
            )
          }
        })
        if (matchingComponent) {
          setSelectedPaymentMethodIndex(checkout.components.indexOf(matchingComponent))
          setValue('adyenOptions', matchingComponent?._id)
        } else {
          setValue('adyenOptions', checkout?.components[0]?._id)
        }
      } else {
        setValue('adyenOptions', checkout?.components[0]?._id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, setValue, isDirty, latestPaymentMethod])

  if (!checkout?.components) return <div />
  return checkout.components.map((component, index) => {
    return (
      <AdyenRadioButton
        watch={watch}
        disabled={disabled}
        onSelect={() => setSelectedPaymentMethodIndex(index)}
        refs={refs}
        key={`${'adyenOptions'}${index}`}
        component={component}
        erroneous={Boolean(get(errors, 'adyenOptions'))}
        {...register('adyenOptions', {
          validate
        })}
      />
    )
  })
}

export default AdyenOptions

import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Parse from 'parse'
import { gql, useQuery } from '@apollo/client'
import { P, B, RouterLink } from '@sellpy/design-system-react-web'
import { selectedSearchListFromPath } from '../selectedSearchListFromPath'
import { currentUserSelector } from '../../../../common/user/selectors'
import { HierarchicalItemList } from '../../../search/components/filters/FilterComponents.jsx'
import { pathToEntity } from '../../../myItems/utils'

export const GET_FAVORITE_LISTS = gql`
  query getFavoriteLists($userId: ID!) {
    itemFavoriteLists(
      where: { user: { have: { objectId: { equalTo: $userId } } }, status: { equalTo: "enabled" } }
    ) {
      edges {
        node {
          objectId
          name
          public
          user {
            objectId
          }
        }
      }
    }
  }
`

export const useGetFavoriteAndRecommendedLists = ({ id, resource, path }) => {
  const {
    selectedList,
    isWeeklyRecommendationsPath,
    isAllFavoritesPath,
    isPreviouslyInCartPath
  } = selectedSearchListFromPath({ id, resource, path })

  const currentUser = useSelector((state) => state.user && currentUserSelector(state))
  const { data: favoriteListsData, loading } = useQuery(GET_FAVORITE_LISTS, {
    variables: { userId: currentUser },
    skip: !currentUser
  })
  const favoriteListsFromGraphql = favoriteListsData?.itemFavoriteLists.edges?.map(
    ({ node }) => node
  )
  const itemRecommendationsForUser = useSelector(
    (state) => state.entities.ItemRecommendationsForUser
  )
  const { t } = useTranslation('favorite')
  const renderFavoriteList = favoriteListsFromGraphql?.map((favoriteList) => ({
    link: `/my-shopping/favorites/list/${favoriteList.objectId}`,
    label: favoriteList.name,
    id: favoriteList.objectId
  }))

  const renderAllFavorites = currentUser && {
    link: '/my-shopping/favorites',
    label: t('allFavorites'),
    id: 'all'
  }

  const renderWeeklyRecommendations = itemRecommendationsForUser.size > 0 && {
    link: `/my-shopping/weekly-recommendations`,
    label: t('thisWeeksRecommendations'),
    id: 'weeklyRecommendation'
  }

  const renderPreviouslyInCart = currentUser && {
    link: `/my-shopping/previously-in-cart`,
    label: t('previouslyInCart'),
    id: 'previouslyInCart'
  }

  const selected = () => {
    if (isWeeklyRecommendationsPath) return 'weeklyRecommendation'
    if (isAllFavoritesPath) return 'all'
    if (isPreviouslyInCartPath) return 'previouslyInCart'
    return selectedList
  }

  return {
    allFavorites: renderAllFavorites,
    favoriteLists: renderFavoriteList,
    weeklyRecommendations: renderWeeklyRecommendations,
    previouslyInCart: renderPreviouslyInCart,
    selected: selected(),
    loading
  }
}

const FavoritesListSelector = () => {
  const params = useParams()
  const { pathname } = useLocation()
  const { id, resource } = params
  const { path } = pathToEntity({ pathname })
  const { allFavorites, favoriteLists, selected } = useGetFavoriteAndRecommendedLists({
    id,
    resource,
    path
  })

  if (!Parse.User.current() || (!allFavorites && !favoriteLists)) return null
  return (
    <div style={{ margin: '1rem 0 0 3rem' }}>
      <HierarchicalItemList scrollRef={null} showMore={true} showFade={favoriteLists?.length > 10}>
        {allFavorites && <Link selected={selected} linkData={allFavorites} />}
        {favoriteLists &&
          favoriteLists.map((favorite) => (
            <Link selected={selected} linkData={favorite} key={favorite.id} />
          ))}
      </HierarchicalItemList>
    </div>
  )
}

export default FavoritesListSelector

const StyledRouterLink = styled(RouterLink)`
  padding-bottom: 0.5rem;
  display: block;
  color: ${({ theme }) => theme.color.grey.shade2};
`

const Link = ({ selected, linkData }) => {
  return (
    <StyledRouterLink to={linkData.link} key={linkData.key}>
      <P style={{ fontSize: '11px' }} noMargin>
        {selected === linkData.id ? <B>{linkData.label}</B> : linkData.label}
      </P>
    </StyledRouterLink>
  )
}

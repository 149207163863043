import { localizedMetadata } from '../../../common/region/locale.js'

const formDefectDescription = ({ fallbackText, defectsDescriptions, photo }) => {
  if (!defectsDescriptions) return
  const defect = defectsDescriptions.find(({ id }) => id === photo.defectId)

  if (!defect || !(defect.type || defect.location)) return fallbackText
  const { type, location } = defect

  return location ? `${type} (${location})` : type
}

const formatFabricDescription = ({ t }) => t('fabricDescription')

const formatDescriptions = ({ type, descriptionProperties }) => {
  switch (type) {
    case 'defect':
      return formDefectDescription(descriptionProperties)
    case 'fabric':
      return formatFabricDescription(descriptionProperties)
    default:
      return null
  }
}

const HM_BUCKET_URL = 'public.assets.hm'

const imageLabelFormat = ({ photo, t, defectsDescriptions, item }) => {
  const activeImageUrl = photo.url
  if (activeImageUrl.includes(HM_BUCKET_URL)) {
    const brand = item.metadata.brand
    return t('carousel.imageFromBrand', { brand })
  }
  const type = photo.type

  return formatDescriptions({
    type,
    descriptionProperties: {
      defectsDescriptions,
      item,
      photo,
      fallbackText: t('defectDefault'),
      t
    }
  })
}

export const getImageLabel = ({ item, ...args }) => {
  const metadata = item[localizedMetadata]
  const defectsDescriptions = metadata?.defects

  return imageLabelFormat({ ...args, defectsDescriptions, item })
}

/**
 * Check if an image is an H&M image asset
 * @param {string} image the image url
 */
export const isHMImage = (image) => image.includes(HM_BUCKET_URL)

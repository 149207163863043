import { gql, useQuery } from '@apollo/client'
import { AB_TESTS, inAbTestTestGroup } from '../../../../common/analytics/abTest'

const GET_LATEST_PAYMENT_METHOD = gql`
  query latestPayment {
    payments(
      order: createdAt_DESC
      where: { completedAt: { exists: true }, adyenPaymentMethod: { exists: true } }
      first: 1
    ) {
      edges {
        node {
          objectId
          adyenPaymentMethod
        }
      }
    }
  }
`

export const useLatestPaymentMethod = () => {
  const { data, loading } = useQuery(GET_LATEST_PAYMENT_METHOD)
  const isInUseLastPaymentMethodTest = inAbTestTestGroup(AB_TESTS.useLastPaymentMethod)

  return {
    latestPaymentMethod: isInUseLastPaymentMethodTest
      ? data?.payments?.edges[0]?.node?.adyenPaymentMethod
      : null,
    loading: isInUseLastPaymentMethodTest ? false : loading
  }
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { user as userTools } from '@sellpy/commons'
import { getCurrentLoyaltyProgramPeriod } from '../../common/entities/selectors/loyaltyProgramPeriod'
import { getCurrentPeriodForUser } from '../../common/loyalty/actions'
import useLoading from './useLoading'

/**
 * Retrieves the currently ongoing loyalty period of the user.
 * @returns {{loading: boolean, loyaltyPeriod: LoyaltyProgramPeriod|undefined}}
 * `loading` = `true` by default, and is `true` while the dispatch is loading.
 *
 * `loyaltyPeriod` = `undefined` while loading, `LoyaltyProgramPeriod` if/when found. If the region does not have the loyalty flag, `loyaltyPeriod` is undefined even if a period exists in the database.
 *
 * @example <caption>Use case examples</caption>
 * import { useLoyalty } from '../path/to/hooks'
 * const { loading, loyaltyPeriod } = useLoyalty()
 *
 * // Only render component when a loyaltyPeriod is present.
 * return !loading && loyaltyPeriod && <SomeComponent />
 *
 * // Do not render if the user has an active subscription (show while loading)
 * return loading && !loyaltyPeriod && <SomeComponent />
 *
 * // Render component, dependent on loyaltyPeriod data
 * return !loading && <SomeComponent loyaltyEndDate={loyaltyPeriod.get('endAt')}/>
 */
const useLoyalty = () => {
  const loyaltyPeriod = useSelector(getCurrentLoyaltyProgramPeriod)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.parseUser)
  const loggedIn = userTools.userIsFull(user)
  const { loading, load } = useLoading(loggedIn)

  useEffect(() => {
    if (loggedIn) load(dispatch(getCurrentPeriodForUser({ region: process.env.REGION })))
  }, [dispatch, load, loggedIn])

  return { loading, loyaltyPeriod }
}

export default useLoyalty

import { DateTime } from 'luxon'
import Parse from 'parse'
import { runQueryCached } from '../lib/parseTools'
import { showToast } from '../ui/actions'
import { DataLayer } from '../analytics/dataLayer'
import { TOAST_TYPE } from '../../client/uiComponents/notifications/Toasts.jsx'
import { cacheEntities, deleteEntity } from '../entities/actions.js'

export const DAC7_REPORT_COMPLETE = 'DAC7_REPORT_COMPLETE'

export const hasDac7BeenReported = () => async (dispatch) =>
  runQueryCached((dac7ReportFiles) =>
    dispatch({ type: DAC7_REPORT_COMPLETE, hasDac7BeenReported: dac7ReportFiles.length > 0 })
  )(
    new Parse.Query('UserSaleReport')
      .equalTo('region', process.env.REGION)
      .lessThan('year', DateTime.local().year)
      .equalTo('latest', true)
      .doesNotExist('invalidatedAt')
      .limit(1)
  )

export const USER_SALE_REPORTS = 'USER_SALE_REPORTS'

export const getUserSaleReports = () => async (dispatch) =>
  runQueryCached((userSaleReports) => {
    dispatch(cacheEntities(userSaleReports))
  })(
    new Parse.Query('UserSaleReport')
      .equalTo('region', process.env.REGION)
      .equalTo('latest', true)
      .doesNotExist('invalidatedAt')
      .lessThan('year', DateTime.local().year)
      .descending('year')
      .include('userIdentificationInformation')
  )

export const getCurrentUserIdentificationInformation = (dispatch) =>
  runQueryCached((userIdentificationInformation) => {
    dispatch(cacheEntities(userIdentificationInformation))
  })(new Parse.Query('UserIdentificationInformation').equalTo('current', true))

export const setUserIdentificationInformation = (
  {
    city,
    country,
    dateOfBirth,
    firstName,
    lastName,
    postalCode,
    streetAddress,
    taxIdentificationNumber,
    taxIdentificationCountry
  },
  closeForms,
  t
) => {
  return async (dispatch) => {
    try {
      const details = {
        city,
        country,
        dateOfBirth,
        firstName,
        lastName,
        postalCode,
        streetAddress,
        taxIdentificationNumber,
        taxIdentificationCountry
      }

      await dispatch(createOrUpdateUserIdentificationInformation(details, null))

      dispatch(
        showToast({
          header: t('balance:balanceOverview.dac7Modal.toast.header'),
          body: t('balance:balanceOverview.dac7Modal.toast.body'),
          type: TOAST_TYPE.SUCCESS
        })
      )
      DataLayer.trackDAC7Completed()
      closeForms()
    } catch (error) {
      dispatch(
        showToast({
          header: t('balance:balanceOverview.dac7Modal.toast.error.header'),
          body: t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }
  }
}

export const createOrUpdateUserIdentificationInformation = (
  information,
  userSaleReportId
) => async (dispatch) => {
  const { userIdentificationInformation, userSaleReport } = await Parse.Cloud.run(
    'createOrUpdateUserIdentificationInformation',
    {
      information,
      userSaleReportId
    }
  )

  if (userSaleReportId)
    dispatch(deleteEntity({ className: 'UserSaleReport', id: userSaleReportId }))

  if (userSaleReport) dispatch(cacheEntities(userSaleReport))

  dispatch(cacheEntities(userIdentificationInformation))
}

import { gql } from '@apollo/client'
import { ITEM_STATUS_MAP } from '../../../item/itemStatus.js'
import { asArrayArgument } from '../helpers.js'
import {
  balanceTransactionFragment,
  createItemFragment,
  marketInventoryFragment,
  pageInfoFragment,
  photosFragment
} from './fragments.js'

export const ITEM_COUNT_QUERY = gql`
  query getCount($saleId: ID!, $statuses: [String!]!) {
    getSaleItemsByStatus(saleId: $saleId, statuses: $statuses, first: 0) {
      count
    }
  }
`

export const AWAITING_APPROVAL_COUNT_QUERY = gql`
    query getAwaitingApproval($saleId: ID!) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.awaitingApproval
    )}, first: 0) {
      count
    }
  }
`

export const ITEM_PER_PAGE = 50

export const createAwaitingApprovalQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${marketInventoryFragment}
  ${photosFragment}

  query getAwaitingApproval($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.awaitingApproval
    )}, first: ${itemPerPage}, after: $after, ) {
      count
      edges {
        node {
          ...itemFragment
          ...marketInventoryFragment
          ...photosFragment
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createProcessingQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${photosFragment}

  query getProcessing($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.processing
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
        }
      }
      ...pageInfoFragment
    }
  }
`
export const createSortedForRecyclingQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${photosFragment}

  query getSortedForRecycling($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.sortedForRecycling
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createReEvaluationRequestedQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}

  query getReEvaluationRequested($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.reEvaluationRequested
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createPublishedQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${photosFragment}
  ${marketInventoryFragment}

  query getPublished($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.published
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
          ...marketInventoryFragment
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createSoldQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${balanceTransactionFragment}
  ${photosFragment}

  query getSold($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.sold
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
          ...balanceTransactionFragment
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createSentBackQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${photosFragment}

  query getSentBack($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.sentBack
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
          item {
            itemStatus
          }
        }
      }
      ...pageInfoFragment
    }
  }
`

export const createDonatedQuery = ({ locale, itemPerPage }) => gql`
  ${createItemFragment(locale)}
  ${pageInfoFragment}
  ${photosFragment}

  query getDonated($saleId: ID!, $after: String) {
    getSaleItemsByStatus(saleId: $saleId, statuses: ${asArrayArgument(
      ITEM_STATUS_MAP.donated
    )}, first: ${itemPerPage}, after: $after) {
      count
      edges {
        node {
          ...itemFragment
          ...photosFragment
          item {
            itemStatus
          }
        }
      }
      ...pageInfoFragment
    }
  }
`
export const GET_ITEM_SORTED_FOR_RECYCLING = gql`
  query sortedForRecycling($itemId: ID!) {
    getDonateAfter(itemId: $itemId)
    getSendBackRequestForItem(itemId: $itemId) {
      objectId
    }
    getSaleRequestForItem(itemId: $itemId) {
      objectId
    }
    hasItemActiveSupportTasks(itemId: $itemId)
  }
`
export const GET_ITEM_PROCESSED = gql`
  query processed($itemId: ID!) {
    hasItemActiveChangeRequests(itemId: $itemId)
  }
`

export const GET_SALES_OVERVIEW_ENTRIES = gql`
  query getSalesOverviewEntriesV2($tab: String, $currentPage: Int) {
    getSalesOverviewEntries: getSalesOverviewEntriesV2(tab: $tab, currentPage: $currentPage)
  }
`

export const ITEM_COMMENCE_TIME_QUERY = gql`
  query getItemCommenceTime {
    getItemCommenceTime
  }
`

export const localeVariables = (locale) => ({
  isSV: locale === 'sv',
  isDE: locale === 'de',
  isEN: locale === 'en',
  isNL: locale === 'nl',
  isDA: locale === 'da',
  isPL: locale === 'pl',
  isFI: locale === 'fi',
  isFR: locale === 'fr'
})

import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import preventScroll from 'prevent-scroll'
import { H1, ICONS, Icon, Button } from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { useClickOutside, useEscapeKey } from '../../hooks'
import {
  Container,
  Header,
  Overlay,
  Content,
  HR,
  DesktopOnlyCancelButton,
  ButtonContainer,
  BottomMargin
} from './styles'

const SimpleModal = ({
  className,
  isOpen,
  children,
  headline,
  onClose,
  bottomCTA,
  showCancelButton = true
}) => {
  return (
    <SimpleModalWrapper isOpen={isOpen} headline={headline} className={className} onClose={onClose}>
      <SimpleModalHeader headline={headline} onClose={onClose} isOpen={isOpen} />
      <SimpleModalContent>{children}</SimpleModalContent>
      <SimpleModalControls
        bottomCTA={bottomCTA}
        showCancelButton={showCancelButton}
        onClose={onClose}
      />
    </SimpleModalWrapper>
  )
}

export const SimpleModalControls = ({ bottomCTA, showCancelButton, onClose }) => {
  const { t } = useTranslation('common')

  return bottomCTA ? (
    <>
      <HR />
      <ButtonContainer>
        {showCancelButton && (
          <DesktopOnlyCancelButton
            label={t('common:cancel')}
            variant={'text'}
            onClick={onClose}
            type={'button'}
          />
        )}
        {bottomCTA}
      </ButtonContainer>
    </>
  ) : (
    <BottomMargin />
  )
}

export const SimpleModalHeader = ({ headline, onClose }) => {
  return (
    <>
      <Header>
        {headline && (
          <H1 noMargin design='h6'>
            {headline}
          </H1>
        )}
        <Button
          onClick={onClose}
          centerIcon={<Icon name={ICONS.CLOSE} style={{ fontSize: '2rem' }} />}
          variant={'text'}
          type={'button'}
          size={'small'}
        />
      </Header>
      <HR />
    </>
  )
}

export const SimpleModalContent = ({ children }) => {
  return <Content>{children}</Content>
}

export const SimpleModalWrapper = ({ className, isOpen, onClose, headline, children }) => {
  const ref = useRef()

  useEffect(() => {
    if (isOpen) preventScroll.on()
    else preventScroll.off()
    // cleanup
    return () => preventScroll.off()
  }, [isOpen])

  useClickOutside(ref, onClose)

  useEscapeKey(ref, onClose)

  const [shouldRender, setRender] = useState(isOpen)

  useEffect(() => {
    if (isOpen) setRender(true)
  }, [isOpen])

  const onAnimationEnd = () => {
    if (!isOpen) setRender(false)
  }

  return shouldRender ? (
    <Overlay isOpen={isOpen} className={className}>
      <Container
        isOpen={isOpen}
        onAnimationEnd={onAnimationEnd}
        ref={ref}
        role='dialog'
        aria-labelledby={headline}
        aria-modal={isOpen}
      >
        {children}
      </Container>
    </Overlay>
  ) : null
}

SimpleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headline: PropTypes.string,
  onClose: PropTypes.func
}

export default SimpleModal

import { RouterLink, Span } from '@sellpy/design-system-react-web'
import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { gql, useQuery } from '@apollo/client'
import { PromoBannerBone } from '../routing/skeletons/PromoBannerSkeleton.jsx'
import { region } from '../../common/region/region.js'
import PromoBanner from './PromoBanner.jsx'

const StyledLink = styled(RouterLink)`
  color: ${({ theme }) => theme.color.black.default};
  text-decoration: underline;
`

const StyledPromoCode = styled(Span)`
  color: ${({ theme, tint }) =>
    ({
      red: theme.color.red.shade1,
      yellow: theme.color.red.default,
      green: theme.color.green.shade1,
      blue: theme.color.blue.default
    }[tint])};
`

const getSerializers = (tint) => ({
  types: {
    // eslint-disable-next-line react/display-name
    block: ({ children }) => <span>{children}</span>
  },
  marks: {
    // eslint-disable-next-line react/display-name
    link: ({ children, mark }) => {
      try {
        const href = new URL(mark.href)
        const actualLink = href && `${href.pathname}${href.search}${href.hash}`
        return <StyledLink to={actualLink}>{children}</StyledLink>
      } catch (e) {
        console.error('Error parsing link', e)
        return null
      }
    },
    // eslint-disable-next-line react/display-name
    strong: ({ children }) => {
      return (
        <Span noMargin design='body4'>
          {children}
        </Span>
      )
    },
    // eslint-disable-next-line react/display-name
    promoCode: ({ children }) => {
      return (
        <StyledPromoCode noMargin design='body4' tint={tint}>
          {children}
        </StyledPromoCode>
      )
    }
  }
})

const PROMOBANNER_QUERY = gql`
  query promoBanner($region: String!, $variant: String!) {
    allPromoBanner(where: { region: { eq: $region }, variant: { eq: $variant } }) {
      firstRowRichTextRaw
      secondRowRichTextRaw
      text
      expandedText
      tint
    }
  }
`

const SanityPromoBanner = ({ variant }) => {
  const { data, loading } = useQuery(PROMOBANNER_QUERY, {
    variables: { region: region(), variant },
    context: { api: 'sanity' }
  })

  if (loading) return <PromoBannerBone />

  const promoBannerCmsData = data?.allPromoBanner?.[0]
  if (!promoBannerCmsData) return null

  const tint = promoBannerCmsData?.tint || 'blue'
  const firstRowRichText = promoBannerCmsData?.firstRowRichTextRaw
  const secondRowRichTextRaw =
    promoBannerCmsData?.secondRowRichTextRaw?.length > 0
      ? promoBannerCmsData?.secondRowRichTextRaw
      : null

  return firstRowRichText ? (
    <PromoBanner
      firstRowContent={
        <BlockContent blocks={firstRowRichText} serializers={getSerializers(tint)} />
      }
      secondRowContent={
        secondRowRichTextRaw ? (
          <BlockContent blocks={secondRowRichTextRaw} serializers={getSerializers(tint)} />
        ) : (
          undefined
        )
      }
      tint={tint}
    />
  ) : (
    <PromoBanner
      firstRowContent={promoBannerCmsData.text}
      secondRowContent={promoBannerCmsData.expandedText}
      tint={tint}
    />
  )
}

export default SanityPromoBanner
